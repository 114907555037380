import { useState } from 'react'
import { ColorsContext } from '../Context'
import { alpha, useTheme } from '@mui/material'

export const ColorsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const theme = useTheme()
  const [colorMap] = useState(new Map<string, string>())
  const [colorUsageCount] = useState(new Map<string, number>())

  const defaultColors = [
    theme.color.dodgerBlue,
    theme.color.coral,
    theme.color.green,
    theme.color.purpure,
    theme.color.superRareJade,
    theme.color.white,
  ]

  const getColorForCompany = (company: string) => {
    if (!colorMap.has(company)) {
      // Get the next base color in rotation
      const baseColorIndex = colorMap.size % defaultColors.length
      const baseColor = defaultColors[baseColorIndex]

      // Count how many times this base color has been used
      const usageCount = colorUsageCount.get(baseColor) || 0
      colorUsageCount.set(baseColor, usageCount + 1)

      // Calculate opacity based on usage count
      // First use: 1, Second use: 0.6, Third use: 0.4, etc.
      const opacity = Math.max(1 - usageCount + 1 * 0.6, 0.4)
      const finalColor =
        usageCount === 0 ? baseColor : alpha(baseColor, opacity)
      colorMap.set(company, finalColor)
    }
    return colorMap.get(company)!
  }

  return (
    <ColorsContext.Provider value={{ getColorForCompany }}>
      {children}
    </ColorsContext.Provider>
  )
}
