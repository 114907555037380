import { Dayjs } from 'dayjs'
import { Maybe } from 'graphql/jsutils/Maybe'
import React from 'react'
import { ROLES } from '../Common/constants'
import { RefinitivDataType } from '../__generated__/graphql'
import { NavigationContext } from './navigation-context'

export type Industry = string

export type IndustryContext = {
  industryId?: string
  setIndustry?: (industryId?: string) => void
}

export type TimeSeriesContext = {
  startDate?: Maybe<Dayjs>
  endDate?: Maybe<Dayjs>
  setStartDate?: (startDate: Maybe<Dayjs>) => void
  setEndDate?: (endDate: Maybe<Dayjs>) => void
}

export type Client = {
  clientId?: Maybe<string>
  clientName?: Maybe<string>
  clientCode?: Maybe<string>
}

export type Company = {
  value: string
  code?: string
  label: string
}

export type ClientContext = {
  client?: Client
  setClient?: (client: Client) => void
}

export type VariableGroup = {
  label?: string
  value: string
}

export type VariableGroupContext = {
  group?: VariableGroup
  setGroup?: (group?: VariableGroup) => void
}

export type CompanyContext = {
  company?: Company
  setCompany?: (company?: Company) => void
}

export type YearContext = {
  year?: number
  setYear?: (year?: number) => void
}

export type ChatBotUserContext = {
  description: string
  data: unknown
}

export type ChatBotAIContext = {
  isChatVisible?: boolean
  context?: ChatBotUserContext
  setChatIsVisible?: (isVisible?: boolean) => void
  setChatContext?: (context?: ChatBotUserContext) => void
}

export type RefinitivDataSourceContext = {
  dataSource?: RefinitivDataType
  setDataSource?: (dataSource: RefinitivDataType) => void
}
export type UserInfo = {
  email?: string
  role?: (typeof ROLES)[keyof typeof ROLES]
}

export type UserInfoContext = {
  userInfo?: Maybe<UserInfo>
  setUserInfo?: (userInfo: UserInfo) => void
}

type ColorContextType = {
  getColorForCompany: (company: string) => string
}

const UserInfoContext = React.createContext<UserInfoContext>({})
const ClientContext = React.createContext<ClientContext>({})
const YearContext = React.createContext<YearContext>({})
const CompanyContext = React.createContext<CompanyContext>({})
const IndustryContext = React.createContext<IndustryContext>({})
const VariableGroupContext = React.createContext<VariableGroupContext>({})
const TimeSeriesContext = React.createContext<TimeSeriesContext>({})
const ChatBotAIContext = React.createContext<ChatBotAIContext>({})
const DataSourceContext = React.createContext<RefinitivDataSourceContext>({})
const ColorsContext = React.createContext<ColorContextType | undefined>(
  undefined,
)
export {
  ChatBotAIContext,
  ClientContext,
  CompanyContext,
  DataSourceContext,
  IndustryContext,
  NavigationContext,
  TimeSeriesContext,
  UserInfoContext,
  VariableGroupContext,
  YearContext,
  ColorsContext,
}
