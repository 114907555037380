/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  Upload: { input: any; output: any }
}

export type Alert = {
  __typename?: 'Alert'
  avg_score: Scalars['Float']['output']
  daily_avg_score: Scalars['Float']['output']
  definition: Scalars['String']['output']
  difference: Scalars['Float']['output']
  stddev: Scalars['Float']['output']
  trend: Scalars['Float']['output']
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type AnalystRating = {
  __typename?: 'AnalystRating'
  actionPt?: Maybe<Scalars['String']['output']>
  analyst?: Maybe<Scalars['String']['output']>
  analystName?: Maybe<Scalars['String']['output']>
  date: Scalars['String']['output']
  ptCurrent?: Maybe<Scalars['Float']['output']>
  ptPrior?: Maybe<Scalars['Float']['output']>
  ratingCurrent?: Maybe<Scalars['String']['output']>
  ratingPrior?: Maybe<Scalars['String']['output']>
}

export type Article = {
  __typename?: 'Article'
  amplification?: Maybe<Scalars['Float']['output']>
  author?: Maybe<Scalars['String']['output']>
  country?: Maybe<Scalars['String']['output']>
  domain?: Maybe<Scalars['String']['output']>
  lang?: Maybe<Scalars['String']['output']>
  media?: Maybe<Scalars['String']['output']>
  pagerank?: Maybe<Scalars['Float']['output']>
  pillar_id?: Maybe<Scalars['Int']['output']>
  pillar_name?: Maybe<Scalars['String']['output']>
  published_on?: Maybe<Scalars['String']['output']>
  reach?: Maybe<Scalars['Int']['output']>
  sentiment?: Maybe<Scalars['String']['output']>
  sentiment_neg?: Maybe<Scalars['Float']['output']>
  sentiment_pos?: Maybe<Scalars['Float']['output']>
  signature_variable_id?: Maybe<Scalars['Int']['output']>
  signature_variable_name?: Maybe<Scalars['String']['output']>
  summary?: Maybe<Scalars['String']['output']>
  title?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

export type ArticlesCount = {
  __typename?: 'ArticlesCount'
  date: Scalars['String']['output']
  total: Scalars['Int']['output']
}

export type ArticlesSentiment = {
  __typename?: 'ArticlesSentiment'
  negative?: Maybe<Scalars['Float']['output']>
  neutral?: Maybe<Scalars['Float']['output']>
  positive?: Maybe<Scalars['Float']['output']>
}

export type AverageRating = {
  __typename?: 'AverageRating'
  current: Scalars['Float']['output']
  nextQuarter: Scalars['String']['output']
  predicted: Scalars['Float']['output']
  quarter: Scalars['String']['output']
  reputationAttributes: Array<Scalars['String']['output']>
}

export type AverageStockPrice = {
  __typename?: 'AverageStockPrice'
  avg_stock: Scalars['Float']['output']
}

export type BehaviorDataRecord = {
  __typename?: 'BehaviorDataRecord'
  behavior_score?: Maybe<Scalars['Float']['output']>
  community?: Maybe<Scalars['Float']['output']>
  community_development?: Maybe<Scalars['Float']['output']>
  company_id?: Maybe<Scalars['String']['output']>
  compliance_reporting?: Maybe<Scalars['Float']['output']>
  customers?: Maybe<Scalars['Float']['output']>
  dei?: Maybe<Scalars['Float']['output']>
  diversity?: Maybe<Scalars['Float']['output']>
  employee_compensation?: Maybe<Scalars['Float']['output']>
  employee_engagement?: Maybe<Scalars['Float']['output']>
  employees?: Maybe<Scalars['Float']['output']>
  environment?: Maybe<Scalars['Float']['output']>
  ethics?: Maybe<Scalars['Float']['output']>
  financial_performance?: Maybe<Scalars['Float']['output']>
  financials?: Maybe<Scalars['Float']['output']>
  governance?: Maybe<Scalars['Float']['output']>
  health_safety?: Maybe<Scalars['Float']['output']>
  human_rights_and_equality?: Maybe<Scalars['Float']['output']>
  leadership?: Maybe<Scalars['Float']['output']>
  loyalty?: Maybe<Scalars['Float']['output']>
  net_zero?: Maybe<Scalars['Float']['output']>
  products_services?: Maybe<Scalars['Float']['output']>
  quarter?: Maybe<Scalars['Int']['output']>
  reach?: Maybe<Scalars['Float']['output']>
  resource_management?: Maybe<Scalars['Float']['output']>
  supplier?: Maybe<Scalars['Float']['output']>
  vision?: Maybe<Scalars['Float']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type BehaviorInfluencer = {
  __typename?: 'BehaviorInfluencer'
  articles?: Maybe<Array<Influencer>>
  pillar_id: Scalars['Int']['output']
}

export type BehaviorPillarScoreV2 = {
  __typename?: 'BehaviorPillarScoreV2'
  company_name: Scalars['String']['output']
  pillars: Array<BehaviorPillarV2>
  signatures: Array<BehaviorSignatureV2>
}

export type BehaviorPillarV2 = {
  __typename?: 'BehaviorPillarV2'
  pillar_id: Scalars['Int']['output']
  pillar_name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type BehaviorScore = {
  __typename?: 'BehaviorScore'
  company_name?: Maybe<Scalars['String']['output']>
  value: Scalars['Float']['output']
}

export type BehaviorScoreV2 = {
  __typename?: 'BehaviorScoreV2'
  change?: Maybe<Scalars['Float']['output']>
  company_id: Scalars['Int']['output']
  company_name: Scalars['String']['output']
  previous_score?: Maybe<Scalars['Float']['output']>
  score: Scalars['Float']['output']
}

export type BehaviorSignatureV2 = {
  __typename?: 'BehaviorSignatureV2'
  pillar_id: Scalars['Int']['output']
  pillar_name: Scalars['String']['output']
  variables: Array<BehaviorVariableV2>
}

export type BehaviorVariableV2 = {
  __typename?: 'BehaviorVariableV2'
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type BrandData = {
  __typename?: 'BrandData'
  date?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type Brands = {
  __typename?: 'Brands'
  company?: Maybe<Scalars['String']['output']>
  data?: Maybe<Array<Maybe<BrandData>>>
}

export type CategorizedArticleJob = {
  __typename?: 'CategorizedArticleJob'
  createdAt?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  jobId: Scalars['String']['output']
  jobsLeft?: Maybe<Scalars['Int']['output']>
  rate?: Maybe<Scalars['Float']['output']>
  result?: Maybe<Scalars['String']['output']>
  startDate?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
  tag?: Maybe<Scalars['String']['output']>
}

export type ChangeVariablesResponse = {
  __typename?: 'ChangeVariablesResponse'
  code: Scalars['String']['output']
  data?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Chat = {
  __typename?: 'Chat'
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  messages?: Maybe<Array<Maybe<Message>>>
  userId: Scalars['ID']['output']
}

export type Client = {
  __typename?: 'Client'
  code?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type ClientSubscription = {
  __typename?: 'ClientSubscription'
  client: Client
  createdAt?: Maybe<Scalars['String']['output']>
  endDate?: Maybe<Scalars['String']['output']>
  features?: Maybe<Array<Maybe<PaidFeature>>>
  id: Scalars['ID']['output']
  startDate?: Maybe<Scalars['String']['output']>
  updatedAt?: Maybe<Scalars['String']['output']>
}

export type Company = {
  __typename?: 'Company'
  assetCode?: Maybe<Scalars['String']['output']>
  caseSensitiveTerms?: Maybe<Scalars['String']['output']>
  client?: Maybe<Client>
  clientCode?: Maybe<Scalars['String']['output']>
  code?: Maybe<Scalars['String']['output']>
  competitors?: Maybe<Array<Maybe<CompanyCompetitor>>>
  countryCode?: Maybe<Scalars['String']['output']>
  domains?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  domainsTerms?: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  industry?: Maybe<Industry>
  name: Scalars['String']['output']
  terms?: Maybe<Scalars['String']['output']>
  ticker?: Maybe<Scalars['String']['output']>
  website?: Maybe<Scalars['String']['output']>
}

export type CompanyCompetitor = {
  __typename?: 'CompanyCompetitor'
  companyId: Scalars['ID']['output']
  competitorId: Scalars['ID']['output']
  id: Scalars['ID']['output']
}

export type CompanyGain = {
  __typename?: 'CompanyGain'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  differenceVsPrevious?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type Competitor = {
  __typename?: 'Competitor'
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export enum ContentType {
  Chart = 'chart',
  File = 'file',
  Image = 'image',
  Markdown = 'markdown',
  Text = 'text',
}

export type CoverageMix = {
  __typename?: 'CoverageMix'
  count?: Maybe<Scalars['Int']['output']>
  percent?: Maybe<Scalars['Float']['output']>
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type CreateCompanyInput = {
  assetCode?: InputMaybe<Scalars['String']['input']>
  caseSensitiveTerms?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  competitorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  countryCode?: InputMaybe<Scalars['String']['input']>
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  domainsTerms?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
  terms?: InputMaybe<Scalars['String']['input']>
  ticker?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type Customer = {
  __typename?: 'Customer'
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type CustomerReport = {
  __typename?: 'CustomerReport'
  createdAt?: Maybe<Scalars['String']['output']>
  customer?: Maybe<Customer>
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
}

export type DailyMentions = {
  __typename?: 'DailyMentions'
  date: Scalars['String']['output']
  mentions: Scalars['Int']['output']
}

export type DailyStockMean = {
  __typename?: 'DailyStockMean'
  avg?: Maybe<Scalars['Float']['output']>
  date: Scalars['String']['output']
}

export type DataForSignatureMatch = {
  __typename?: 'DataForSignatureMatch'
  company?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  original?: Maybe<Scalars['Float']['output']>
  originalVsCompany?: Maybe<Scalars['Float']['output']>
  scoreVsCompany?: Maybe<Scalars['Float']['output']>
  y?: Maybe<Scalars['Float']['output']>
}

export type DeleteCompanyResponse = {
  __typename?: 'DeleteCompanyResponse'
  deleted: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
}

export type EmailWhitelist = {
  __typename?: 'EmailWhitelist'
  clientId: Scalars['ID']['output']
  emailOrDomain: Scalars['String']['output']
  id: Scalars['ID']['output']
}

export type ExecutiveSummarySentimentScore = {
  __typename?: 'ExecutiveSummarySentimentScore'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  score?: Maybe<Scalars['Float']['output']>
}

export type ExploreCompanyData = {
  __typename?: 'ExploreCompanyData'
  average?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  categories?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  data?: Maybe<Array<Maybe<ExploreCompanyDataSlice>>>
}

export type ExploreCompanyDataSlice = {
  __typename?: 'ExploreCompanyDataSlice'
  color?: Maybe<Scalars['String']['output']>
  data?: Maybe<Array<Maybe<Scalars['Float']['output']>>>
  name?: Maybe<Scalars['String']['output']>
}

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse'
  code: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  result?: Maybe<Array<Maybe<Scalars['String']['output']>>>
  success: Scalars['Boolean']['output']
}

export type FinancialInfluencer = MediaArticle & {
  __typename?: 'FinancialInfluencer'
  domain: Scalars['String']['output']
  published_on: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type FinancialInfluencerScore = {
  __typename?: 'FinancialInfluencerScore'
  count: Scalars['Int']['output']
  published_on: Scalars['String']['output']
  variable_id: Scalars['ID']['output']
  variable_name: Scalars['String']['output']
}

export type FinancialMediaMentions = {
  __typename?: 'FinancialMediaMentions'
  media_mentions: Scalars['Int']['output']
}

export type FinancialRating = {
  __typename?: 'FinancialRating'
  averageRating?: Maybe<Scalars['Float']['output']>
  buy?: Maybe<Scalars['Float']['output']>
  hold?: Maybe<Scalars['Float']['output']>
  month: Scalars['String']['output']
  overperform?: Maybe<Scalars['Float']['output']>
  sell?: Maybe<Scalars['Float']['output']>
  underperform?: Maybe<Scalars['Float']['output']>
}

export type FinancialSentimentScore = {
  __typename?: 'FinancialSentimentScore'
  company_id: Scalars['ID']['output']
  company_name: Scalars['String']['output']
  date?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type Industry = {
  __typename?: 'Industry'
  forClient?: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type Influencer = MediaArticle & {
  __typename?: 'Influencer'
  domain: Scalars['String']['output']
  published_on: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Influencers = {
  __typename?: 'Influencers'
  values?: Maybe<Array<Influencer>>
}

export type Insight = {
  __typename?: 'Insight'
  companyId: Scalars['Int']['output']
  created: Scalars['String']['output']
  summary: Scalars['String']['output']
}

export type KeyOpportunity = {
  __typename?: 'KeyOpportunity'
  result_score: Scalars['Float']['output']
  variable_name: Scalars['String']['output']
}

export type MarketShare = {
  __typename?: 'MarketShare'
  company_id: Scalars['ID']['output']
  company_name: Scalars['String']['output']
  value?: Maybe<Scalars['Float']['output']>
}

export type MediaArticle = {
  domain: Scalars['String']['output']
  published_on: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type Mention = {
  __typename?: 'Mention'
  company: Scalars['String']['output']
  companyId: Scalars['String']['output']
  dataType: RefinitivDataType
  mentions: Scalars['Int']['output']
  timestamp: Scalars['String']['output']
}

export type Mentions = {
  __typename?: 'Mentions'
  companyId: Scalars['ID']['output']
  companyName: Scalars['String']['output']
  count?: Maybe<Scalars['Int']['output']>
}

export type Message = {
  __typename?: 'Message'
  chatId: Scalars['ID']['output']
  content: Scalars['String']['output']
  contentType?: Maybe<ContentType>
  createdAt: Scalars['String']['output']
  id: Scalars['ID']['output']
  senderType: Scalars['String']['output']
}

export type MessageInput = {
  content: Scalars['String']['input']
  senderId: Scalars['ID']['input']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Adds a new company */
  addCompany?: Maybe<Company>
  /** Add email to client's whitelist */
  addEmailToWhitelist: MutationResponse
  /** Adds a new subscription */
  addSubscription?: Maybe<ClientSubscription>
  calculateBehaviorData: Array<PullBehaviorDataJob>
  calculateSentimentData: Array<PullSentimentDataJob>
  cancelCategorizationJobs: Scalars['Boolean']['output']
  /** Change logged-in user password. */
  changePassword: MutationResponse
  /** Change user password. New password cannot be the same as an existing one. */
  changeUserPassword: MutationResponse
  /** Change Variable score */
  changeVariableScore?: Maybe<ChangeVariablesResponse>
  createChat?: Maybe<Chat>
  createMessage?: Maybe<Message>
  /** Creates a new user */
  createUser: UserMutationResponse
  deleteChat?: Maybe<Scalars['Boolean']['output']>
  /** Deletes a company */
  deleteCompany?: Maybe<DeleteCompanyResponse>
  /** Deletes a customer report */
  deleteCustomerReport: ReportCommandResult
  /** Removes user by user's email */
  deleteUser: UserMutationResponse
  /** Updates an existing user w/ new info */
  editUser: UserMutationResponse
  /** Sign in user */
  login: User
  /** Sign out current user */
  logout: Scalars['Boolean']['output']
  markNotificationAsRead: Scalars['Boolean']['output']
  pullArticles: Array<PullOnclusiveArticlesJob>
  pullCategorizedArticles: Array<PullCategorizedArticlesJob>
  pullCompanyExplorerData: Array<PullCompanyExplorerDataJob>
  pullInsights: Array<PullInsightsJob>
  pullRefinitivData: PullRefinitivDataJob
  pullSentimentHistoryData: Array<PullSentimentHistoryDataJob>
  /** Reset Variable score */
  resetVariableScore?: Maybe<ChangeVariablesResponse>
  scoreArticles: Scalars['Boolean']['output']
  /** Send email containing link to reset user's password */
  sendResetPasswordEmail: MutationResponse
  /** Updates a existing company */
  updateCompany?: Maybe<Company>
  /**
   * Update user information.
   * This mutation should be invoked by the user as part of sign-up process.
   */
  updateUserInfo: MutationResponse
  /** Uploads customer report data */
  uploadCustomerReportFile: ReportCommandResult
  /** Uploads users roster data */
  uploadUsersRosterFile: FileUploadResponse
  /** Verify reset password token */
  verifyResetPasswordToken: VerifyTokenResponse
}

export type MutationAddCompanyArgs = {
  company: CreateCompanyInput
}

export type MutationAddEmailToWhitelistArgs = {
  clientId: Scalars['ID']['input']
  emailOrDomain: Scalars['String']['input']
}

export type MutationAddSubscriptionArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  features: Array<PaidFeature>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type MutationCalculateBehaviorDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input']
}

export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String']['input']
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type MutationChangeVariableScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
  value?: InputMaybe<Scalars['Float']['input']>
  variableName: Scalars['String']['input']
}

export type MutationCreateMessageArgs = {
  chatId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  input: Scalars['String']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type MutationCreateUserArgs = {
  input: UserInput
}

export type MutationDeleteChatArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteCustomerReportArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteUserArgs = {
  email: Scalars['String']['input']
}

export type MutationEditUserArgs = {
  input: UserInput
}

export type MutationLoginArgs = {
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationMarkNotificationAsReadArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationPullArticlesArgs = {
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullCategorizedArticlesArgs = {
  companies?: InputMaybe<Array<Scalars['Int']['input']>>
  endDate: Scalars['String']['input']
  limit: Scalars['Int']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullCompanyExplorerDataArgs = {
  date: Scalars['String']['input']
}

export type MutationPullInsightsArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type MutationPullRefinitivDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationPullSentimentHistoryDataArgs = {
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type MutationResetVariableScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
}

export type MutationSendResetPasswordEmailArgs = {
  email: Scalars['String']['input']
  redirectTo: Scalars['String']['input']
}

export type MutationUpdateCompanyArgs = {
  company: UpdateCompanyInput
  id: Scalars['ID']['input']
}

export type MutationUpdateUserInfoArgs = {
  jobTitle: Scalars['String']['input']
  teamId: Scalars['ID']['input']
}

export type MutationUploadCustomerReportFileArgs = {
  clientId: Scalars['ID']['input']
  file: Scalars['Upload']['input']
}

export type MutationUploadUsersRosterFileArgs = {
  clientId: Scalars['ID']['input']
  file: Scalars['Upload']['input']
}

export type MutationVerifyResetPasswordTokenArgs = {
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type MutationResponse = {
  __typename?: 'MutationResponse'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
}

export type Notification = {
  __typename?: 'Notification'
  createdAt: Scalars['String']['output']
  id: Scalars['Int']['output']
  message: Scalars['String']['output']
  read: Scalars['Boolean']['output']
}

export enum PaidFeature {
  Onclusive = 'ONCLUSIVE',
}

export type Perception = {
  __typename?: 'Perception'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type PerceptionRealityGap = {
  __typename?: 'PerceptionRealityGap'
  perception?: Maybe<Array<Maybe<Perception>>>
  signature?: Maybe<Array<Maybe<Signature>>>
}

export type PerceptionRealityGapScore = {
  __typename?: 'PerceptionRealityGapScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type PillarKeyResult = {
  __typename?: 'PillarKeyResult'
  behavior_score?: Maybe<Scalars['Float']['output']>
  date?: Maybe<Scalars['String']['output']>
  key: Scalars['String']['output']
  key_score?: Maybe<Scalars['Float']['output']>
  sentiment_score?: Maybe<Scalars['Float']['output']>
  variable_name: Scalars['String']['output']
}

export type PillarMentions = {
  __typename?: 'PillarMentions'
  company_name: Scalars['String']['output']
  date: Scalars['String']['output']
  mentions: Scalars['Int']['output']
}

export type PillarMentionShareVoice = {
  __typename?: 'PillarMentionShareVoice'
  company_name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type PillarNewsSentiment = {
  __typename?: 'PillarNewsSentiment'
  sentiment_type: Scalars['String']['output']
  value: Scalars['Int']['output']
}

export type PillarPotentialScore = {
  __typename?: 'PillarPotentialScore'
  avg?: Maybe<Scalars['Float']['output']>
  company_name: Scalars['String']['output']
  potential?: Maybe<Scalars['Float']['output']>
}

export type PillarReputationScore = {
  __typename?: 'PillarReputationScore'
  current_value?: Maybe<Scalars['Float']['output']>
  id?: Maybe<Scalars['Int']['output']>
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type PillarScore = {
  __typename?: 'PillarScore'
  avg?: Maybe<Scalars['Float']['output']>
  company_name: Scalars['String']['output']
  date?: Maybe<Scalars['String']['output']>
}

export type PillarVariableInfluencer = {
  __typename?: 'PillarVariableInfluencer'
  change?: Maybe<Scalars['Float']['output']>
  company_id?: Maybe<Scalars['Float']['output']>
  company_name: Scalars['String']['output']
  current_avg: Scalars['Float']['output']
  prev_avg?: Maybe<Scalars['Float']['output']>
  variable_id: Scalars['Float']['output']
  variable_name: Scalars['String']['output']
}

export type PillarVariableInfluencerArticle = MediaArticle & {
  __typename?: 'PillarVariableInfluencerArticle'
  domain: Scalars['String']['output']
  published_on: Scalars['String']['output']
  title: Scalars['String']['output']
  url: Scalars['String']['output']
  variable_id: Scalars['Int']['output']
}

export type PillarVelocity = {
  __typename?: 'PillarVelocity'
  company_name: Scalars['String']['output']
  last_avg?: Maybe<Scalars['Float']['output']>
  prev_avg?: Maybe<Scalars['Float']['output']>
  result?: Maybe<Scalars['Float']['output']>
}

export type PullBehaviorDataJob = {
  __typename?: 'PullBehaviorDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullCategorizedArticlesJob = {
  __typename?: 'PullCategorizedArticlesJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullCompanyExplorerDataJob = {
  __typename?: 'PullCompanyExplorerDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullInsightsJob = {
  __typename?: 'PullInsightsJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullOnclusiveArticlesJob = {
  __typename?: 'PullOnclusiveArticlesJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullRefinitivDataJob = {
  __typename?: 'PullRefinitivDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullSentimentDataJob = {
  __typename?: 'PullSentimentDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PullSentimentHistoryDataJob = {
  __typename?: 'PullSentimentHistoryDataJob'
  jobId: Scalars['String']['output']
  message?: Maybe<Scalars['String']['output']>
  status?: Maybe<Scalars['String']['output']>
}

export type PurposeDriver = {
  __typename?: 'PurposeDriver'
  color?: Maybe<Scalars['String']['output']>
  compareVsPeriod?: Maybe<Scalars['String']['output']>
  differenceWithPastQuarter?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type PurposePillar = {
  __typename?: 'PurposePillar'
  color?: Maybe<Scalars['String']['output']>
  compareVs?: Maybe<Scalars['String']['output']>
  drilldown?: Maybe<Array<Maybe<SignatureVariable>>>
  name?: Maybe<Scalars['String']['output']>
  optimum?: Maybe<Scalars['Float']['output']>
  optimumDrilldown?: Maybe<Array<Maybe<SignatureVariable>>>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type PurposeSignature = {
  __typename?: 'PurposeSignature'
  company?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  data?: Maybe<Array<Maybe<PurposeSignatureDate>>>
  dataForSignatureMatch?: Maybe<Array<Maybe<DataForSignatureMatch>>>
  optimum?: Maybe<Array<Maybe<PurposeSignatureDate>>>
}

export type PurposeSignatureDate = {
  __typename?: 'PurposeSignatureDate'
  current?: Maybe<Scalars['Float']['output']>
  name?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type Query = {
  __typename?: 'Query'
  alerts?: Maybe<Array<Alert>>
  analystRatings: Array<AnalystRating>
  /** Get the list of articles for client */
  articles: Array<Article>
  /** Get the list of articles for company */
  articlesByCompany: Array<Article>
  /** Get the list of articles for every pillar for client */
  articlesByPillars: Array<Article>
  /** Get the list of articles for Trending Topics for client */
  articlesByTrendingTopics: Array<Article>
  /** Get the list of all articles count for client */
  articlesCount: Array<ArticlesCount>
  /** Get the sentiment distribution for client (positive, negative, neutral) */
  articlesSentiment: ArticlesSentiment
  /** Get the list of articles for client for positive, negative and overall sentiment */
  articlesSentimentOvertime: Array<Article>
  averageRatings: AverageRating
  averageStockPrice: AverageStockPrice
  behaviorData: Array<BehaviorDataRecord>
  behaviorInfluencingArticles: Array<BehaviorInfluencer>
  behaviorPillarsScores: Array<BehaviorPillarScoreV2>
  behaviorScores: Array<BehaviorScoreV2>
  categorizedArticlesJobs?: Maybe<Array<CategorizedArticleJob>>
  chat?: Maybe<Chat>
  chats?: Maybe<Array<Maybe<Chat>>>
  /** Check if a client has active subscription */
  checkActiveSubscription: Scalars['Boolean']['output']
  /** Check if user's email is in whitelist for selected client */
  checkIfEmailWhitelisted?: Maybe<Scalars['Boolean']['output']>
  /** Get the list of all clients */
  clients: Array<Client>
  /** Get the list of all companies */
  companies: Array<Company>
  /** companies for Purpose Signature Overview chart */
  companiesInPurposeSignatureChart?: Maybe<Array<Maybe<Company>>>
  /** Company vs. Top-Most Purpose Driven companies */
  companyVsTopMostPurposeDriven?: Maybe<Array<Maybe<PurposeDriver>>>
  competitorsMarketShare: Array<MarketShare>
  competitorsRelevanceScore: Array<RelevanceScore>
  competitorsReputationScore: Array<Competitor>
  competitorsSentimentScore: Array<SentimentScoreV2>
  coverageMix: Array<CoverageMix>
  /** Get current authenticated user from cookies */
  currentUser?: Maybe<User>
  /** Returns all customer reports */
  customerReports: Array<CustomerReport>
  customerReportsByClient: Array<CustomerReport>
  dailyMentions?: Maybe<Array<Maybe<Mention>>>
  dailyStocks: Array<DailyStockMean>
  downloadPerceptionRealityGapData?: Maybe<Scalars['String']['output']>
  /** Get email whitelist */
  emailWhitelist: Array<EmailWhitelist>
  /** Explore company data by industry */
  exploreDataByIndustry?: Maybe<ExploreCompanyData>
  /** Explore company data by year */
  exploreDataByYear?: Maybe<ExploreCompanyData>
  financialInfluencerScore: Array<FinancialInfluencerScore>
  financialInfluencingArticles: Array<FinancialInfluencer>
  financialMediaMentions: FinancialMediaMentions
  financialSentimentDailyScore: Array<FinancialSentimentScore>
  financialSentimentScore: Array<FinancialSentimentScore>
  /** High Risk areas */
  highRiskAreas?: Maybe<Array<Maybe<PurposePillar>>>
  /** Fetch industries */
  industries: Array<Industry>
  insights?: Maybe<Insight>
  keyOpportunities?: Maybe<Array<KeyOpportunity>>
  marketShare: ReputationScoreV2
  mediaMentions: ReputationScoreV2
  messages?: Maybe<Array<Maybe<Message>>>
  notifications: Array<Notification>
  perceptionRealityGap?: Maybe<PerceptionRealityGap>
  perceptionRealityGapScore?: Maybe<Array<Maybe<PerceptionRealityGapScore>>>
  pillarAvgScore: Array<PillarScore>
  pillarBehaviorScore: BehaviorScore
  pillarBehaviorTopCompetitorScore: BehaviorScore
  pillarDailyScore: Array<PillarScore>
  pillarKeyResults: Array<PillarKeyResult>
  pillarMentionsCount: Array<PillarMentions>
  pillarMentionShareVoice: Array<PillarMentionShareVoice>
  pillarNewsSentiment: Array<PillarNewsSentiment>
  pillarPotentialScore: Array<PillarPotentialScore>
  pillarReputationChangesScore: Array<PillarReputationScore>
  pillarReputationScore: Array<PillarReputationScore>
  pillarVariableInfluencing: Array<PillarVariableInfluencer>
  pillarVariableInfluencingArticle: Array<PillarVariableInfluencerArticle>
  pillarVelocity: Array<PillarVelocity>
  pullArticlesJobStatus: Array<PullOnclusiveArticlesJob>
  pullCalculateBehaviorDataJobStatus: Array<PullBehaviorDataJob>
  pullCalculateSentimentDataJobStatus: Array<PullSentimentDataJob>
  pullCategorizedArticlesJobStatus: Array<PullCategorizedArticlesJob>
  pullCompanyExplorerDataJobStatus: Array<PullCompanyExplorerDataJob>
  pullInsightsJobStatus: Array<PullInsightsJob>
  pullRefinitivDataJobStatus: PullRefinitivDataJob
  pullSentimentHistoryDataJobStatus: Array<PullSentimentHistoryDataJob>
  /** Purpose drivers data */
  purposeDriversSignature?: Maybe<Array<Maybe<PurposeDriver>>>
  /** Get purpose match value */
  purposeMatch?: Maybe<Scalars['Float']['output']>
  /** Purpose pillars */
  purposePillars?: Maybe<Array<Maybe<PurposePillar>>>
  /** data for Purpose Signature Overview chart */
  purposeSignature?: Maybe<PurposeSignature>
  rankedSentimentDrivers: Array<SentimentDriver>
  ratings: Array<FinancialRating>
  reputationInfluencers: Influencers
  /** Reputation score */
  reputationScore?: Maybe<ReputationScore>
  reputationScoreChangeV2: ReputationScoreV2
  reputationScoreDriverV2: PillarReputationScore
  reputationScorePotential: ReputationScoreV2
  reputationScoreV2: ReputationScoreV2
  scoreSentimentGapBehavior: Array<ScoreSentimentGapBehavior>
  scoresRelevanceGraph?: Maybe<Array<RelevanceScore>>
  scoresReputationGraph?: Maybe<Array<Brands>>
  scoresSentimentGraph?: Maybe<Array<Brands>>
  sentimentByPillar?: Maybe<Array<Maybe<SentimentData>>>
  sentimentBySignature?: Maybe<Array<Maybe<SentimentData>>>
  sentimentData: Array<SentimentDataRecord>
  sentimentExecutiveSummary?: Maybe<SentimentExecutiveSummary>
  sentimentMentions: Array<SentimentMentions>
  sentimentOvertimeScore?: Maybe<SentimentOverTime>
  sentimentPillarChanges: Array<SentimentPillarChange>
  sentimentScore?: Maybe<Array<Maybe<SentimentScore>>>
  sentimentVelocity: Array<SentimentVelocity>
  sentimentVoiceShares: Array<SentimentVoiceShare>
  /** data for Purpose Signature Simulator */
  sessionPurposeSignature?: Maybe<PurposeSignature>
  /** Fet the share voice distribution for client */
  shareVoice: Array<ShareVoice>
  signatureExecutiveSummary?: Maybe<SignatureExecutiveSummary>
  /** Signature match */
  signatureMatch?: Maybe<Array<Maybe<PurposePillar>>>
  stockChange: Array<StockChange>
  stockClosePriceOvertime: Array<StockClosePrice>
  /** Get list of all subscriptions */
  subscriptions: Array<ClientSubscription>
  /** data for Team Purpose Signature Overview chart */
  teamPurposeSignature?: Maybe<PurposeSignature>
  topCompetitorMentions: Array<PillarVariableInfluencerArticle>
  topics: Array<Topic>
  trafficValue: ReputationScoreV2
  trendingTopics?: Maybe<Array<Maybe<TrendingTopic>>>
  /** Get all users information */
  userDetails: Array<UserDetail>
  /** Variable Group (ESG, HR, CFO, DEI) */
  variableGroups?: Maybe<Array<Maybe<VariableGroup>>>
  variableOvertimeScore?: Maybe<Array<Maybe<VariableOverTimeScore>>>
  variablesInfluencingSentiment: Array<SentimentVariableChange>
  /** Years for Purpose Signature Overview chart */
  yearsInPurposeSignatureChart?: Maybe<Array<Maybe<Scalars['Int']['output']>>>
}

export type QueryAlertsArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  startDate: Scalars['String']['input']
}

export type QueryAnalystRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryArticlesArgs = {
  clientId: Scalars['ID']['input']
  endDate: Scalars['String']['input']
  pillarId?: InputMaybe<Scalars['Int']['input']>
  startDate: Scalars['String']['input']
  variableId?: InputMaybe<Scalars['Int']['input']>
}

export type QueryArticlesByCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesByPillarsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  variableId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryArticlesByTrendingTopicsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  variableId: Scalars['ID']['input']
}

export type QueryArticlesCountArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesSentimentArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryArticlesSentimentOvertimeArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  sentiment: Sentiment
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryAverageRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryAverageStockPriceArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryBehaviorDataArgs = {
  clientId: Scalars['ID']['input']
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type QueryBehaviorInfluencingArticlesArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryBehaviorPillarsScoresArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryBehaviorScoresArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryChatArgs = {
  id: Scalars['ID']['input']
}

export type QueryCheckActiveSubscriptionArgs = {
  features: Array<PaidFeature>
}

export type QueryCheckIfEmailWhitelistedArgs = {
  client: Scalars['String']['input']
  email: Scalars['String']['input']
}

export type QueryCompaniesInPurposeSignatureChartArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryCompanyVsTopMostPurposeDrivenArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryCompetitorsMarketShareArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompetitorsRelevanceScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompetitorsReputationScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryCompetitorsSentimentScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryCoverageMixArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryCustomerReportsByClientArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryDailyMentionsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryDailyStocksArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryDownloadPerceptionRealityGapDataArgs = {
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryEmailWhitelistArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryExploreDataByIndustryArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryExploreDataByYearArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryFinancialInfluencerScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryFinancialInfluencingArticlesArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryFinancialMediaMentionsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryFinancialSentimentDailyScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryFinancialSentimentScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryHighRiskAreasArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryIndustriesArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryInsightsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryKeyOpportunitiesArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMarketShareArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMediaMentionsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryMessagesArgs = {
  chatId: Scalars['ID']['input']
}

export type QueryPerceptionRealityGapArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPerceptionRealityGapScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPillarAvgScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarBehaviorScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarBehaviorTopCompetitorScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarDailyScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarKeyResultsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarMentionsCountArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarMentionShareVoiceArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarNewsSentimentArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarPotentialScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarReputationChangesScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarReputationScoreArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarVariableInfluencingArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarVariableInfluencingArticleArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPillarVelocityArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryPullArticlesJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCalculateBehaviorDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCalculateSentimentDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCategorizedArticlesJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullCompanyExplorerDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullInsightsJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPullRefinitivDataJobStatusArgs = {
  jobId?: InputMaybe<Scalars['String']['input']>
}

export type QueryPullSentimentHistoryDataJobStatusArgs = {
  jobs: Array<Scalars['String']['input']>
}

export type QueryPurposeDriversSignatureArgs = {
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPurposeMatchArgs = {
  clientId: Scalars['ID']['input']
}

export type QueryPurposePillarsArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryPurposeSignatureArgs = {
  companyId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  year: Scalars['Int']['input']
}

export type QueryRankedSentimentDriversArgs = {
  industryId: Scalars['ID']['input']
}

export type QueryRatingsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReputationInfluencersArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryReputationScoreChangeV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreDriverV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScorePotentialArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryReputationScoreV2Args = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoreSentimentGapBehaviorArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoresRelevanceGraphArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoresReputationGraphArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryScoresSentimentGraphArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentByPillarArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentBySignatureArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentDataArgs = {
  clientId: Scalars['ID']['input']
  end?: InputMaybe<Scalars['String']['input']>
  start?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentExecutiveSummaryArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  source?: InputMaybe<RefinitivDataSource>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentMentionsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentOvertimeScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentPillarChangesArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentVelocityArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySentimentVoiceSharesArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySessionPurposeSignatureArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  groupId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  restoreUserSession?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryShareVoiceArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QuerySignatureExecutiveSummaryArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QuerySignatureMatchArgs = {
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}

export type QueryStockChangeArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryStockClosePriceOvertimeArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTeamPurposeSignatureArgs = {
  companyId: Scalars['ID']['input']
  groupId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
  year: Scalars['Int']['input']
}

export type QueryTopCompetitorMentionsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  pillarId: Scalars['Int']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTopicsArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTrafficValueArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryTrendingTopicsArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryUserDetailsArgs = {
  clientId: Scalars['ID']['input']
  teamsIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>
}

export type QueryVariableOvertimeScoreArgs = {
  clientId?: InputMaybe<Scalars['ID']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  industryId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
}

export type QueryVariablesInfluencingSentimentArgs = {
  clientId: Scalars['ID']['input']
  endDate?: InputMaybe<Scalars['String']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
}

export enum RefinitivDataSource {
  News = 'News',
  NewsSocial = 'News_Social',
  Social = 'Social',
}

export enum RefinitivDataType {
  News = 'News',
  NewsSocial = 'News_Social',
  Social = 'Social',
}

export type RelevanceScore = {
  __typename?: 'RelevanceScore'
  company: Scalars['String']['output']
  date?: Maybe<Scalars['String']['output']>
  value: Scalars['Float']['output']
}

export type ReportCommandResult = {
  __typename?: 'ReportCommandResult'
  code: Scalars['Int']['output']
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type ReputationDriver = {
  __typename?: 'ReputationDriver'
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
  weight?: Maybe<Scalars['Float']['output']>
}

export type ReputationScore = {
  __typename?: 'ReputationScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  compareVsPeriod?: Maybe<Scalars['String']['output']>
  difference?: Maybe<Scalars['Float']['output']>
  leaderScore?: Maybe<Scalars['Float']['output']>
  score: Scalars['Float']['output']
}

export type ReputationScoreV2 = {
  __typename?: 'ReputationScoreV2'
  value: Scalars['Float']['output']
}

export type Role = {
  __typename?: 'Role'
  name: Scalars['String']['output']
  rank: Scalars['Int']['output']
}

export type ScoreSentimentGapBehavior = {
  __typename?: 'ScoreSentimentGapBehavior'
  behavior: Scalars['Float']['output']
  gap: Scalars['Float']['output']
  sentiment: Scalars['Float']['output']
  variable_name: Scalars['String']['output']
}

export enum Sentiment {
  Negative = 'negative',
  Neutral = 'neutral',
  Positive = 'positive',
}

export type SentimentData = {
  __typename?: 'SentimentData'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type SentimentDataRecord = {
  __typename?: 'SentimentDataRecord'
  community?: Maybe<Scalars['Float']['output']>
  community_development?: Maybe<Scalars['Float']['output']>
  company_id?: Maybe<Scalars['String']['output']>
  compliance_reporting?: Maybe<Scalars['Float']['output']>
  customers?: Maybe<Scalars['Float']['output']>
  dei?: Maybe<Scalars['Float']['output']>
  diversity?: Maybe<Scalars['Float']['output']>
  employee_compensation?: Maybe<Scalars['Float']['output']>
  employee_engagement?: Maybe<Scalars['Float']['output']>
  employees?: Maybe<Scalars['Float']['output']>
  environment?: Maybe<Scalars['Float']['output']>
  ethics?: Maybe<Scalars['Float']['output']>
  financial_performance?: Maybe<Scalars['Float']['output']>
  financials?: Maybe<Scalars['Float']['output']>
  governance?: Maybe<Scalars['Float']['output']>
  health_safety?: Maybe<Scalars['Float']['output']>
  human_rights_and_equality?: Maybe<Scalars['Float']['output']>
  leadership?: Maybe<Scalars['Float']['output']>
  loyalty?: Maybe<Scalars['Float']['output']>
  month?: Maybe<Scalars['Int']['output']>
  net_zero?: Maybe<Scalars['Float']['output']>
  products_services?: Maybe<Scalars['Float']['output']>
  reach?: Maybe<Scalars['Float']['output']>
  resource_management?: Maybe<Scalars['Float']['output']>
  sentiment_score?: Maybe<Scalars['Float']['output']>
  supplier?: Maybe<Scalars['Float']['output']>
  vision?: Maybe<Scalars['Float']['output']>
  year?: Maybe<Scalars['Int']['output']>
}

export type SentimentDriver = {
  __typename?: 'SentimentDriver'
  pillarName: Scalars['String']['output']
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type SentimentExecutiveSummary = {
  __typename?: 'SentimentExecutiveSummary'
  mentions?: Maybe<Array<Maybe<Mentions>>>
  score?: Maybe<Array<Maybe<ExecutiveSummarySentimentScore>>>
}

export type SentimentMentions = {
  __typename?: 'SentimentMentions'
  company_id: Scalars['Int']['output']
  company_name: Scalars['String']['output']
  mentions: Array<DailyMentions>
  total_mentions: Scalars['Int']['output']
}

export type SentimentOverTime = {
  __typename?: 'SentimentOverTime'
  negativeSentiment?: Maybe<Array<Maybe<SentimentScore>>>
  overallSentiment?: Maybe<Array<Maybe<SentimentScore>>>
  positiveSentiment?: Maybe<Array<Maybe<SentimentScore>>>
}

export type SentimentPillarChange = {
  __typename?: 'SentimentPillarChange'
  diff: Scalars['Float']['output']
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type SentimentScore = {
  __typename?: 'SentimentScore'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  date?: Maybe<Scalars['String']['output']>
  leaderScore?: Maybe<Scalars['Float']['output']>
  score?: Maybe<Scalars['Float']['output']>
}

export type SentimentScoreV2 = {
  __typename?: 'SentimentScoreV2'
  name: Scalars['String']['output']
  value: Scalars['Float']['output']
}

export type SentimentVariableChange = {
  __typename?: 'SentimentVariableChange'
  diff: Scalars['Float']['output']
  pillar_id?: Maybe<Scalars['Int']['output']>
  pillar_name: Scalars['String']['output']
  value: Scalars['Float']['output']
  variable_id?: Maybe<Scalars['Int']['output']>
  variable_name: Scalars['String']['output']
}

export type SentimentVelocity = {
  __typename?: 'SentimentVelocity'
  company_name: Scalars['String']['output']
  last_avg: Scalars['Float']['output']
  prev_avg?: Maybe<Scalars['Float']['output']>
  result?: Maybe<Scalars['Float']['output']>
}

export type SentimentVoiceShare = {
  __typename?: 'SentimentVoiceShare'
  company_name: Scalars['String']['output']
  voice_share: Scalars['Float']['output']
}

export type ShareVoice = {
  __typename?: 'ShareVoice'
  company: Scalars['String']['output']
  share?: Maybe<Scalars['Int']['output']>
}

export type Signature = {
  __typename?: 'Signature'
  name?: Maybe<Scalars['String']['output']>
  optimum?: Maybe<Scalars['Float']['output']>
  pillar?: Maybe<Scalars['String']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type SignatureExecutiveSummary = {
  __typename?: 'SignatureExecutiveSummary'
  competitorsGains?: Maybe<Array<Maybe<CompanyGain>>>
  drivers?: Maybe<Array<Maybe<ReputationDriver>>>
}

export type SignatureVariable = {
  __typename?: 'SignatureVariable'
  compareVs?: Maybe<Scalars['String']['output']>
  drilldown?: Maybe<Array<Maybe<Variable>>>
  name?: Maybe<Scalars['String']['output']>
  optimumDrilldown?: Maybe<Array<Maybe<Variable>>>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type StockChange = {
  __typename?: 'StockChange'
  company_id: Scalars['ID']['output']
  company_name: Scalars['String']['output']
  value?: Maybe<Scalars['Float']['output']>
}

export type StockClosePrice = {
  __typename?: 'StockClosePrice'
  adjustedClosingPrice?: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['String']['output']
}

export type Team = {
  __typename?: 'Team'
  childTeams?: Maybe<Array<Maybe<Team>>>
  client?: Maybe<Client>
  hideChildren?: Maybe<Scalars['Boolean']['output']>
  id?: Maybe<Scalars['ID']['output']>
  isDepartment?: Maybe<Scalars['Boolean']['output']>
  name?: Maybe<Scalars['String']['output']>
  parentTeamId?: Maybe<Scalars['ID']['output']>
}

export type Topic = {
  __typename?: 'Topic'
  company_id: Scalars['ID']['output']
  company_name: Scalars['String']['output']
  value?: Maybe<Scalars['Float']['output']>
  variable_id: Scalars['Int']['output']
  variable_name: Scalars['String']['output']
}

export type TrendingTopic = {
  __typename?: 'TrendingTopic'
  averageScore?: Maybe<Scalars['Float']['output']>
  dataType: RefinitivDataType
  pillarId?: Maybe<Scalars['ID']['output']>
  pillarName?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableId: Scalars['ID']['output']
  variableName: Scalars['String']['output']
}

export type UpdateCompanyInput = {
  assetCode?: InputMaybe<Scalars['String']['input']>
  caseSensitiveTerms?: InputMaybe<Scalars['String']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  competitorIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  countryCode?: InputMaybe<Scalars['String']['input']>
  domains?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  domainsTerms?: InputMaybe<Scalars['String']['input']>
  industryId?: InputMaybe<Scalars['String']['input']>
  name?: InputMaybe<Scalars['String']['input']>
  terms?: InputMaybe<Scalars['String']['input']>
  ticker?: InputMaybe<Scalars['String']['input']>
  website?: InputMaybe<Scalars['String']['input']>
}

export type User = {
  __typename?: 'User'
  clientCode?: Maybe<Scalars['String']['output']>
  clientId?: Maybe<Scalars['ID']['output']>
  clientName?: Maybe<Scalars['String']['output']>
  email: Scalars['String']['output']
  emailHash: Scalars['String']['output']
  id: Scalars['ID']['output']
  respondentId?: Maybe<Scalars['ID']['output']>
  respondentUuid?: Maybe<Scalars['String']['output']>
  roles: Array<Role>
  settings?: Maybe<UserSettings>
}

export type UserDetail = {
  __typename?: 'UserDetail'
  accountStatus: Scalars['String']['output']
  email: Scalars['String']['output']
  id?: Maybe<Scalars['ID']['output']>
  jobTitle?: Maybe<Scalars['String']['output']>
  role?: Maybe<Role>
  roleId?: Maybe<Scalars['ID']['output']>
}

export type UserInput = {
  accountStatus?: InputMaybe<Scalars['String']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  email: Scalars['String']['input']
  id?: InputMaybe<Scalars['ID']['input']>
  mustResetPassword?: InputMaybe<Scalars['Boolean']['input']>
  password?: InputMaybe<Scalars['String']['input']>
  roleId: Scalars['ID']['input']
}

export type UserMutationResponse = {
  __typename?: 'UserMutationResponse'
  code: Scalars['String']['output']
  message: Scalars['String']['output']
  success: Scalars['Boolean']['output']
  user?: Maybe<UserDetail>
}

export type UserSettings = {
  __typename?: 'UserSettings'
  mustResetPassword?: Maybe<Scalars['Boolean']['output']>
}

export type Variable = {
  __typename?: 'Variable'
  compareVs?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  previousValue?: Maybe<Scalars['Float']['output']>
  value?: Maybe<Scalars['Float']['output']>
}

export type VariableGroup = {
  __typename?: 'VariableGroup'
  id?: Maybe<Scalars['ID']['output']>
  name?: Maybe<Scalars['String']['output']>
}

export type VariableOverTimeScore = {
  __typename?: 'VariableOverTimeScore'
  dataType: RefinitivDataType
  date?: Maybe<Scalars['String']['output']>
  pillarName?: Maybe<Scalars['String']['output']>
  score?: Maybe<Scalars['Float']['output']>
  variableName?: Maybe<Scalars['String']['output']>
  variableWeight?: Maybe<Scalars['Float']['output']>
}

export type VerifyTokenResponse = {
  __typename?: 'VerifyTokenResponse'
  code: Scalars['String']['output']
  email?: Maybe<Scalars['String']['output']>
  message?: Maybe<Scalars['String']['output']>
  success: Scalars['Boolean']['output']
}

export type LoginMutationVariables = Exact<{
  email: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login: {
    __typename?: 'User'
    email: string
    respondentUuid?: string | null
    clientName?: string | null
    clientCode?: string | null
    clientId?: string | null
    roles: Array<{ __typename?: 'Role'; name: string; rank: number }>
  }
}

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean }

export type CreateUserMutationVariables = Exact<{
  input: UserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: {
      __typename?: 'UserDetail'
      id?: string | null
      email: string
      roleId?: string | null
      role?: { __typename?: 'Role'; name: string; rank: number } | null
    } | null
  }
}

export type DeleteUserMutationVariables = Exact<{
  email: Scalars['String']['input']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: { __typename?: 'UserDetail'; email: string } | null
  }
}

export type EditUserMutationVariables = Exact<{
  input: UserInput
}>

export type EditUserMutation = {
  __typename?: 'Mutation'
  editUser: {
    __typename?: 'UserMutationResponse'
    code: string
    success: boolean
    message: string
    user?: {
      __typename?: 'UserDetail'
      id?: string | null
      email: string
      roleId?: string | null
      accountStatus: string
      role?: { __typename?: 'Role'; name: string; rank: number } | null
    } | null
  }
}

export type UploadUsersRosterFileMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  clientId: Scalars['ID']['input']
}>

export type UploadUsersRosterFileMutation = {
  __typename?: 'Mutation'
  uploadUsersRosterFile: {
    __typename?: 'FileUploadResponse'
    code: string
    success: boolean
    message?: string | null
    result?: Array<string | null> | null
  }
}

export type SendResetPasswordEmailMutationVariables = Exact<{
  email: Scalars['String']['input']
  redirectTo: Scalars['String']['input']
}>

export type SendResetPasswordEmailMutation = {
  __typename?: 'Mutation'
  sendResetPasswordEmail: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type VerifyResetPasswordTokenMutationVariables = Exact<{
  userId: Scalars['ID']['input']
  token: Scalars['String']['input']
}>

export type VerifyResetPasswordTokenMutation = {
  __typename?: 'Mutation'
  verifyResetPasswordToken: {
    __typename?: 'VerifyTokenResponse'
    code: string
    success: boolean
    message?: string | null
    email?: string | null
  }
}

export type ChangeUserPasswordMutationVariables = Exact<{
  token: Scalars['String']['input']
  userId: Scalars['ID']['input']
  newPassword: Scalars['String']['input']
}>

export type ChangeUserPasswordMutation = {
  __typename?: 'Mutation'
  changeUserPassword: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input']
}>

export type ChangePasswordMutation = {
  __typename?: 'Mutation'
  changePassword: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type AddEmailToWhitelistMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
  emailOrDomain: Scalars['String']['input']
}>

export type AddEmailToWhitelistMutation = {
  __typename?: 'Mutation'
  addEmailToWhitelist: {
    __typename?: 'MutationResponse'
    code: string
    success: boolean
    message: string
  }
}

export type ChangeVariableScoreMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
  variableName: Scalars['String']['input']
  value?: InputMaybe<Scalars['Float']['input']>
}>

export type ChangeVariableScoreMutation = {
  __typename?: 'Mutation'
  changeVariableScore?: {
    __typename?: 'ChangeVariablesResponse'
    code: string
    success: boolean
    message: string
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
  } | null
}

export type ResetVariableScoreMutationVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId: Scalars['ID']['input']
}>

export type ResetVariableScoreMutation = {
  __typename?: 'Mutation'
  resetVariableScore?: {
    __typename?: 'ChangeVariablesResponse'
    code: string
    success: boolean
    message: string
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
  } | null
}

export type PullRefinitivDataMutationVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>
  end?: InputMaybe<Scalars['String']['input']>
}>

export type PullRefinitivDataMutation = {
  __typename?: 'Mutation'
  pullRefinitivData: {
    __typename?: 'PullRefinitivDataJob'
    status?: string | null
    message?: string | null
    jobId: string
  }
}

export type CreateChatMutationVariables = Exact<{ [key: string]: never }>

export type CreateChatMutation = {
  __typename?: 'Mutation'
  createChat?: { __typename?: 'Chat'; id: string } | null
}

export type CreateMessageMutationVariables = Exact<{
  chatId: Scalars['ID']['input']
  input: Scalars['String']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type CreateMessageMutation = {
  __typename?: 'Mutation'
  createMessage?: {
    __typename?: 'Message'
    id: string
    content: string
    contentType?: ContentType | null
  } | null
}

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>

export type CurrentUserQuery = {
  __typename?: 'Query'
  currentUser?: {
    __typename?: 'User'
    respondentUuid?: string | null
    clientName?: string | null
    clientCode?: string | null
    clientId?: string | null
    email: string
    emailHash: string
    roles: Array<{ __typename?: 'Role'; name: string; rank: number }>
    settings?: {
      __typename?: 'UserSettings'
      mustResetPassword?: boolean | null
    } | null
  } | null
}

export type ClientsQueryVariables = Exact<{ [key: string]: never }>

export type ClientsQuery = {
  __typename?: 'Query'
  clients: Array<{
    __typename?: 'Client'
    id: string
    name: string
    code?: string | null
  }>
}

export type UserDetailsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  teamsIds?: InputMaybe<
    | Array<InputMaybe<Scalars['ID']['input']>>
    | InputMaybe<Scalars['ID']['input']>
  >
}>

export type UserDetailsQuery = {
  __typename?: 'Query'
  userDetails: Array<{
    __typename?: 'UserDetail'
    id?: string | null
    email: string
    roleId?: string | null
    accountStatus: string
    jobTitle?: string | null
    role?: { __typename?: 'Role'; name: string; rank: number } | null
  }>
}

export type EmailWhitelistQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type EmailWhitelistQuery = {
  __typename?: 'Query'
  emailWhitelist: Array<{
    __typename?: 'EmailWhitelist'
    id: string
    clientId: string
    emailOrDomain: string
  }>
}

export type CheckIfEmailWhitelistedQueryVariables = Exact<{
  client: Scalars['String']['input']
  email: Scalars['String']['input']
}>

export type CheckIfEmailWhitelistedQuery = {
  __typename?: 'Query'
  checkIfEmailWhitelisted?: boolean | null
}

export type YearsInPurposeSignatureChartQueryVariables = Exact<{
  [key: string]: never
}>

export type YearsInPurposeSignatureChartQuery = {
  __typename?: 'Query'
  yearsInPurposeSignatureChart?: Array<number | null> | null
}

export type CompaniesInPurposeSignatureChartQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type CompaniesInPurposeSignatureChartQuery = {
  __typename?: 'Query'
  companiesInPurposeSignatureChart?: Array<{
    __typename?: 'Company'
    id: string
    name: string
    clientCode?: string | null
  } | null> | null
}

export type TeamPurposeSignatureQueryVariables = Exact<{
  year: Scalars['Int']['input']
  companyId: Scalars['ID']['input']
  groupId: Scalars['ID']['input']
  limit?: InputMaybe<Scalars['Int']['input']>
}>

export type TeamPurposeSignatureQuery = {
  __typename?: 'Query'
  teamPurposeSignature?: {
    __typename?: 'PurposeSignature'
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
    optimum?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    dataForSignatureMatch?: Array<{
      __typename?: 'DataForSignatureMatch'
      name?: string | null
      y?: number | null
    } | null> | null
  } | null
}

export type SessionPurposeSignatureQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  groupId?: InputMaybe<Scalars['ID']['input']>
  companyId?: InputMaybe<Scalars['ID']['input']>
  limit?: InputMaybe<Scalars['Int']['input']>
  restoreUserSession?: InputMaybe<Scalars['Boolean']['input']>
}>

export type SessionPurposeSignatureQuery = {
  __typename?: 'Query'
  sessionPurposeSignature?: {
    __typename?: 'PurposeSignature'
    data?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
      current?: number | null
    } | null> | null
    optimum?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    company?: Array<{
      __typename?: 'PurposeSignatureDate'
      score?: number | null
      name?: string | null
    } | null> | null
    dataForSignatureMatch?: Array<{
      __typename?: 'DataForSignatureMatch'
      name?: string | null
      y?: number | null
      original?: number | null
      company?: number | null
      scoreVsCompany?: number | null
      originalVsCompany?: number | null
    } | null> | null
  } | null
}

export type PurposeDriversSignatureQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type PurposeDriversSignatureQuery = {
  __typename?: 'Query'
  purposeDriversSignature?: Array<{
    __typename?: 'PurposeDriver'
    name?: string | null
    value?: number | null
    color?: string | null
    differenceWithPastQuarter?: number | null
    compareVsPeriod?: string | null
  } | null> | null
}

export type ReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    difference?: number | null
    compareVsPeriod?: string | null
  } | null
}

export type CompanyVsTopMostPurposeDrivenQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type CompanyVsTopMostPurposeDrivenQuery = {
  __typename?: 'Query'
  companyVsTopMostPurposeDriven?: Array<{
    __typename?: 'PurposeDriver'
    name?: string | null
    value?: number | null
    color?: string | null
    differenceWithPastQuarter?: number | null
    compareVsPeriod?: string | null
  } | null> | null
}

export type PurposePillarsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type PurposePillarsQuery = {
  __typename?: 'Query'
  purposePillars?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
      drilldown?: Array<{
        __typename?: 'Variable'
        name?: string | null
        value?: number | null
        previousValue?: number | null
        compareVs?: string | null
      } | null> | null
      optimumDrilldown?: Array<{
        __typename?: 'Variable'
        name?: string | null
        value?: number | null
      } | null> | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type HighRiskAreasQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type HighRiskAreasQuery = {
  __typename?: 'Query'
  highRiskAreas?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type SignatureMatchQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type SignatureMatchQuery = {
  __typename?: 'Query'
  signatureMatch?: Array<{
    __typename?: 'PurposePillar'
    name?: string | null
    value?: number | null
    previousValue?: number | null
    compareVs?: string | null
    color?: string | null
    optimum?: number | null
    drilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
      previousValue?: number | null
      compareVs?: string | null
    } | null> | null
    optimumDrilldown?: Array<{
      __typename?: 'SignatureVariable'
      name?: string | null
      value?: number | null
    } | null> | null
  } | null> | null
}

export type ExploreDataByYearQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ExploreDataByYearQuery = {
  __typename?: 'Query'
  exploreDataByYear?: {
    __typename?: 'ExploreCompanyData'
    categories?: Array<string | null> | null
    data?: Array<{
      __typename?: 'ExploreCompanyDataSlice'
      name?: string | null
      color?: string | null
      data?: Array<number | null> | null
    } | null> | null
  } | null
}

export type ExploreDataByIndustryQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type ExploreDataByIndustryQuery = {
  __typename?: 'Query'
  exploreDataByIndustry?: {
    __typename?: 'ExploreCompanyData'
    categories?: Array<string | null> | null
    average?: Array<number | null> | null
    data?: Array<{
      __typename?: 'ExploreCompanyDataSlice'
      name?: string | null
      color?: string | null
      data?: Array<number | null> | null
    } | null> | null
  } | null
}

export type GetTrendingTopicsQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetTrendingTopicsQuery = {
  __typename?: 'Query'
  trendingTopics?: Array<{
    __typename?: 'TrendingTopic'
    score?: number | null
    averageScore?: number | null
    variableId: string
    variableName: string
    pillarName?: string | null
    dataType: RefinitivDataType
  } | null> | null
}

export type IndustriesQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type IndustriesQuery = {
  __typename?: 'Query'
  industries: Array<{
    __typename?: 'Industry'
    id: string
    name: string
    forClient?: boolean | null
  }>
}

export type PurposeMatchQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
}>

export type PurposeMatchQuery = {
  __typename?: 'Query'
  purposeMatch?: number | null
}

export type VariableGroupsQueryVariables = Exact<{ [key: string]: never }>

export type VariableGroupsQuery = {
  __typename?: 'Query'
  variableGroups?: Array<{
    __typename?: 'VariableGroup'
    id?: string | null
    name?: string | null
  } | null> | null
}

export type GetHomeTabSentimentScoreQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabSentimentScoreQuery = {
  __typename?: 'Query'
  sentimentScore?: Array<{
    __typename?: 'SentimentScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type GetHomeTabReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    averageScore?: number | null
    leaderScore?: number | null
  } | null
}

export type GetHomeTabPerceptionRealityGapScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetHomeTabPerceptionRealityGapScoreQuery = {
  __typename?: 'Query'
  perceptionRealityGapScore?: Array<{
    __typename?: 'PerceptionRealityGapScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type CalculateBehaviorDataMutationVariables = Exact<{
  start?: InputMaybe<Scalars['String']['input']>
  end?: InputMaybe<Scalars['String']['input']>
}>

export type CalculateBehaviorDataMutation = {
  __typename?: 'Mutation'
  calculateBehaviorData: Array<{
    __typename?: 'PullBehaviorDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCalculateBehaviorDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCalculateBehaviorDataJobStatusQuery = {
  __typename?: 'Query'
  pullCalculateBehaviorDataJobStatus: Array<{
    __typename?: 'PullBehaviorDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type CalculateSentimentDataMutationVariables = Exact<{
  [key: string]: never
}>

export type CalculateSentimentDataMutation = {
  __typename?: 'Mutation'
  calculateSentimentData: Array<{
    __typename?: 'PullSentimentDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCalculateSentimentDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCalculateSentimentDataJobStatusQuery = {
  __typename?: 'Query'
  pullCalculateSentimentDataJobStatus: Array<{
    __typename?: 'PullSentimentDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type DownloadPerceptionRealityGapDataQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type DownloadPerceptionRealityGapDataQuery = {
  __typename?: 'Query'
  downloadPerceptionRealityGapData?: string | null
}

export type PullArticlesMutationVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type PullArticlesMutation = {
  __typename?: 'Mutation'
  pullArticles: Array<{
    __typename?: 'PullOnclusiveArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullArticlesJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullArticlesJobStatusQuery = {
  __typename?: 'Query'
  pullArticlesJobStatus: Array<{
    __typename?: 'PullOnclusiveArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCategorizedArticlesMutationVariables = Exact<{
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  limit: Scalars['Int']['input']
  companies?: InputMaybe<
    Array<Scalars['Int']['input']> | Scalars['Int']['input']
  >
}>

export type PullCategorizedArticlesMutation = {
  __typename?: 'Mutation'
  pullCategorizedArticles: Array<{
    __typename?: 'PullCategorizedArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type CancelCategorizationJobsMutationVariables = Exact<{
  [key: string]: never
}>

export type CancelCategorizationJobsMutation = {
  __typename?: 'Mutation'
  cancelCategorizationJobs: boolean
}

export type PullCategorizedArticlesJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCategorizedArticlesJobStatusQuery = {
  __typename?: 'Query'
  pullCategorizedArticlesJobStatus: Array<{
    __typename?: 'PullCategorizedArticlesJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCompanyExplorerDataMutationVariables = Exact<{
  date: Scalars['String']['input']
}>

export type PullCompanyExplorerDataMutation = {
  __typename?: 'Mutation'
  pullCompanyExplorerData: Array<{
    __typename?: 'PullCompanyExplorerDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullCompanyExplorerDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullCompanyExplorerDataJobStatusQuery = {
  __typename?: 'Query'
  pullCompanyExplorerDataJobStatus: Array<{
    __typename?: 'PullCompanyExplorerDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullRefinitivDataJobStatusQueryVariables = Exact<{
  jobId: Scalars['String']['input']
}>

export type PullRefinitivDataJobStatusQuery = {
  __typename?: 'Query'
  pullRefinitivDataJobStatus: {
    __typename?: 'PullRefinitivDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }
}

export type PullSentimentHistoryDataMutationVariables = Exact<{
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type PullSentimentHistoryDataMutation = {
  __typename?: 'Mutation'
  pullSentimentHistoryData: Array<{
    __typename?: 'PullSentimentHistoryDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullSentimentHistoryDataJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullSentimentHistoryDataJobStatusQuery = {
  __typename?: 'Query'
  pullSentimentHistoryDataJobStatus: Array<{
    __typename?: 'PullSentimentHistoryDataJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type ScoreArticlesMutationVariables = Exact<{ [key: string]: never }>

export type ScoreArticlesMutation = {
  __typename?: 'Mutation'
  scoreArticles: boolean
}

export type BehaviorDataQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type BehaviorDataQuery = {
  __typename?: 'Query'
  behaviorData: Array<{
    __typename?: 'BehaviorDataRecord'
    company_id?: string | null
    year?: number | null
    quarter?: number | null
    behavior_score?: number | null
    community?: number | null
    customers?: number | null
    dei?: number | null
    employees?: number | null
    environment?: number | null
    financials?: number | null
    governance?: number | null
    community_development?: number | null
    compliance_reporting?: number | null
    diversity?: number | null
    employee_compensation?: number | null
    employee_engagement?: number | null
    ethics?: number | null
    financial_performance?: number | null
    health_safety?: number | null
    human_rights_and_equality?: number | null
    leadership?: number | null
    loyalty?: number | null
    net_zero?: number | null
    products_services?: number | null
    reach?: number | null
    resource_management?: number | null
    supplier?: number | null
    vision?: number | null
  }>
}

export type AddCompanyMutationVariables = Exact<{
  company: CreateCompanyInput
}>

export type AddCompanyMutation = {
  __typename?: 'Mutation'
  addCompany?: {
    __typename?: 'Company'
    id: string
    name: string
    code?: string | null
    countryCode?: string | null
    assetCode?: string | null
    ticker?: string | null
    terms?: string | null
    caseSensitiveTerms?: string | null
    website?: string | null
    domains?: Array<string | null> | null
    domainsTerms?: string | null
    client?: { __typename?: 'Client'; id: string; name: string } | null
    industry?: { __typename?: 'Industry'; id: string; name: string } | null
    competitors?: Array<{
      __typename?: 'CompanyCompetitor'
      id: string
      companyId: string
      competitorId: string
    } | null> | null
  } | null
}

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input']
  company: UpdateCompanyInput
}>

export type UpdateCompanyMutation = {
  __typename?: 'Mutation'
  updateCompany?: {
    __typename?: 'Company'
    id: string
    name: string
    code?: string | null
    countryCode?: string | null
    assetCode?: string | null
    ticker?: string | null
    terms?: string | null
    caseSensitiveTerms?: string | null
    website?: string | null
    domains?: Array<string | null> | null
    domainsTerms?: string | null
    client?: { __typename?: 'Client'; id: string; name: string } | null
    industry?: { __typename?: 'Industry'; id: string; name: string } | null
    competitors?: Array<{
      __typename?: 'CompanyCompetitor'
      id: string
      companyId: string
      competitorId: string
    } | null> | null
  } | null
}

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCompanyMutation = {
  __typename?: 'Mutation'
  deleteCompany?: {
    __typename?: 'DeleteCompanyResponse'
    id: string
    deleted: boolean
  } | null
}

export type CustomerReportsQueryVariables = Exact<{ [key: string]: never }>

export type CustomerReportsQuery = {
  __typename?: 'Query'
  customerReports: Array<{
    __typename?: 'CustomerReport'
    id: number
    name: string
    createdAt?: string | null
    customer?: { __typename?: 'Customer'; id: number; name: string } | null
  }>
}

export type UploadCustomerReportFileMutationVariables = Exact<{
  file: Scalars['Upload']['input']
  clientId: Scalars['ID']['input']
}>

export type UploadCustomerReportFileMutation = {
  __typename?: 'Mutation'
  uploadCustomerReportFile: {
    __typename?: 'ReportCommandResult'
    code: number
    success: boolean
    message?: string | null
  }
}

export type DeleteCustomerReportMutationVariables = Exact<{
  id: Scalars['ID']['input']
}>

export type DeleteCustomerReportMutation = {
  __typename?: 'Mutation'
  deleteCustomerReport: {
    __typename?: 'ReportCommandResult'
    code: number
    success: boolean
    message?: string | null
  }
}

export type CategorizedArticlesJobsQueryVariables = Exact<{
  [key: string]: never
}>

export type CategorizedArticlesJobsQuery = {
  __typename?: 'Query'
  categorizedArticlesJobs?: Array<{
    __typename?: 'CategorizedArticleJob'
    status?: string | null
    jobId: string
    tag?: string | null
    jobsLeft?: number | null
    rate?: number | null
    startDate?: string | null
    endDate?: string | null
    result?: string | null
    createdAt?: string | null
  }> | null
}

export type SentimentDataQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  start: Scalars['String']['input']
  end: Scalars['String']['input']
}>

export type SentimentDataQuery = {
  __typename?: 'Query'
  sentimentData: Array<{
    __typename?: 'SentimentDataRecord'
    company_id?: string | null
    month?: number | null
    year?: number | null
    sentiment_score?: number | null
    community?: number | null
    customers?: number | null
    dei?: number | null
    employees?: number | null
    environment?: number | null
    financials?: number | null
    governance?: number | null
    community_development?: number | null
    compliance_reporting?: number | null
    diversity?: number | null
    employee_compensation?: number | null
    employee_engagement?: number | null
    ethics?: number | null
    financial_performance?: number | null
    health_safety?: number | null
    human_rights_and_equality?: number | null
    leadership?: number | null
    loyalty?: number | null
    net_zero?: number | null
    products_services?: number | null
    reach?: number | null
    resource_management?: number | null
    supplier?: number | null
    vision?: number | null
  }>
}

export type AddSubscriptionMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  features: Array<PaidFeature> | PaidFeature
}>

export type AddSubscriptionMutation = {
  __typename?: 'Mutation'
  addSubscription?: {
    __typename?: 'ClientSubscription'
    id: string
    startDate?: string | null
    endDate?: string | null
    features?: Array<PaidFeature | null> | null
    updatedAt?: string | null
    createdAt?: string | null
    client: {
      __typename?: 'Client'
      id: string
      name: string
      code?: string | null
    }
  } | null
}

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never }>

export type SubscriptionsQuery = {
  __typename?: 'Query'
  subscriptions: Array<{
    __typename?: 'ClientSubscription'
    id: string
    startDate?: string | null
    endDate?: string | null
    features?: Array<PaidFeature | null> | null
    updatedAt?: string | null
    createdAt?: string | null
    client: {
      __typename?: 'Client'
      id: string
      name: string
      code?: string | null
    }
  }>
}

export type CheckActiveSubscriptionQueryVariables = Exact<{
  features: Array<PaidFeature> | PaidFeature
}>

export type CheckActiveSubscriptionQuery = {
  __typename?: 'Query'
  checkActiveSubscription: boolean
}

export type GetAlertsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type GetAlertsQuery = {
  __typename?: 'Query'
  alerts?: Array<{
    __typename?: 'Alert'
    variable_name: string
    variable_id: number
    definition: string
    avg_score: number
    trend: number
    stddev: number
    daily_avg_score: number
    difference: number
  }> | null
}

export type BehaviorInfluencingArticlesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type BehaviorInfluencingArticlesQuery = {
  __typename?: 'Query'
  behaviorInfluencingArticles: Array<{
    __typename?: 'BehaviorInfluencer'
    pillar_id: number
    articles?: Array<{
      __typename?: 'Influencer'
      title: string
      url: string
      domain: string
      published_on: string
    }> | null
  }>
}

export type BehaviorScoresQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type BehaviorScoresQuery = {
  __typename?: 'Query'
  behaviorScores: Array<{
    __typename?: 'BehaviorScoreV2'
    company_name: string
    company_id: number
    score: number
    change?: number | null
  }>
}

export type BehaviorPillarsScoresQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type BehaviorPillarsScoresQuery = {
  __typename?: 'Query'
  behaviorPillarsScores: Array<{
    __typename?: 'BehaviorPillarScoreV2'
    company_name: string
    pillars: Array<{
      __typename?: 'BehaviorPillarV2'
      pillar_name: string
      pillar_id: number
      value: number
    }>
    signatures: Array<{
      __typename?: 'BehaviorSignatureV2'
      pillar_name: string
      pillar_id: number
      variables: Array<{
        __typename?: 'BehaviorVariableV2'
        name: string
        value: number
      }>
    }>
  }>
}

export type GetSentimentBehaviorGapQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentBehaviorGapQuery = {
  __typename?: 'Query'
  scoreSentimentGapBehavior: Array<{
    __typename?: 'ScoreSentimentGapBehavior'
    variable_name: string
    sentiment: number
    gap: number
    behavior: number
  }>
}

export type AverageStockPriceQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type AverageStockPriceQuery = {
  __typename?: 'Query'
  averageStockPrice: { __typename?: 'AverageStockPrice'; avg_stock: number }
}

export type CoverageMixQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type CoverageMixQuery = {
  __typename?: 'Query'
  coverageMix: Array<{
    __typename?: 'CoverageMix'
    variable_id: number
    variable_name: string
    percent?: number | null
    count?: number | null
  }>
}

export type GetDailyReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetDailyReputationScoreQuery = {
  __typename?: 'Query'
  scoresReputationGraph?: Array<{
    __typename?: 'Brands'
    company?: string | null
    data?: Array<{
      __typename?: 'BrandData'
      value?: number | null
      date?: string | null
    } | null> | null
  }> | null
}

export type DailyStocksMeanQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type DailyStocksMeanQuery = {
  __typename?: 'Query'
  dailyStocks: Array<{
    __typename?: 'DailyStockMean'
    date: string
    avg?: number | null
  }>
}

export type FinancialInfluencerScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type FinancialInfluencerScoreQuery = {
  __typename?: 'Query'
  financialInfluencerScore: Array<{
    __typename?: 'FinancialInfluencerScore'
    variable_id: string
    variable_name: string
    published_on: string
    count: number
  }>
}

export type FinancialMediaMentionsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type FinancialMediaMentionsQuery = {
  __typename?: 'Query'
  financialMediaMentions: {
    __typename?: 'FinancialMediaMentions'
    media_mentions: number
  }
}

export type FinancialSentimentDailyScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type FinancialSentimentDailyScoreQuery = {
  __typename?: 'Query'
  financialSentimentDailyScore: Array<{
    __typename?: 'FinancialSentimentScore'
    company_name: string
    company_id: string
    value?: number | null
    date?: string | null
  }>
}

export type FinancialSentimentScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type FinancialSentimentScoreQuery = {
  __typename?: 'Query'
  financialSentimentScore: Array<{
    __typename?: 'FinancialSentimentScore'
    company_name: string
    company_id: string
    value?: number | null
  }>
}

export type FinancialInfluencingArticlesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type FinancialInfluencingArticlesQuery = {
  __typename?: 'Query'
  financialInfluencingArticles: Array<{
    __typename?: 'FinancialInfluencer'
    title: string
    url: string
    domain: string
    published_on: string
    variable_id: number
    variable_name: string
  }>
}

export type CompetitorsMarketShareQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type CompetitorsMarketShareQuery = {
  __typename?: 'Query'
  competitorsMarketShare: Array<{
    __typename?: 'MarketShare'
    company_id: string
    company_name: string
    value?: number | null
  }>
}

export type StockPriceChangeQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type StockPriceChangeQuery = {
  __typename?: 'Query'
  stockChange: Array<{
    __typename?: 'StockChange'
    company_name: string
    company_id: string
    value?: number | null
  }>
}

export type TopicsInfluencingScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type TopicsInfluencingScoreQuery = {
  __typename?: 'Query'
  topics: Array<{
    __typename?: 'Topic'
    company_name: string
    company_id: string
    variable_id: number
    variable_name: string
    value?: number | null
  }>
}

export type GetReputationScoreV2QueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreV2Query = {
  __typename?: 'Query'
  reputationScoreV2: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationScoreChangeQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreChangeQuery = {
  __typename?: 'Query'
  reputationScoreChangeV2: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationScoreDiverQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScoreDiverQuery = {
  __typename?: 'Query'
  reputationScoreDriverV2: {
    __typename?: 'PillarReputationScore'
    name: string
    value: number
    current_value?: number | null
  }
}

export type GetMarketShareQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetMarketShareQuery = {
  __typename?: 'Query'
  marketShare: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetMediaMentionsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetMediaMentionsQuery = {
  __typename?: 'Query'
  mediaMentions: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetReputationInfluencersQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationInfluencersQuery = {
  __typename?: 'Query'
  reputationInfluencers: {
    __typename?: 'Influencers'
    values?: Array<{
      __typename?: 'Influencer'
      title: string
      url: string
      domain: string
      published_on: string
    }> | null
  }
}

export type GetTrafficValueQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetTrafficValueQuery = {
  __typename?: 'Query'
  trafficValue: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetPillarReputationQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetPillarReputationQuery = {
  __typename?: 'Query'
  pillarReputationScore: Array<{
    __typename?: 'PillarReputationScore'
    name: string
    value: number
    id?: number | null
  }>
}

export type GetPillarReputationChangesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetPillarReputationChangesQuery = {
  __typename?: 'Query'
  pillarReputationChangesScore: Array<{
    __typename?: 'PillarReputationScore'
    name: string
    value: number
  }>
}

export type CompetitorsRelevanceScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type CompetitorsRelevanceScoreQuery = {
  __typename?: 'Query'
  competitorsRelevanceScore: Array<{
    __typename?: 'RelevanceScore'
    company: string
    value: number
  }>
}

export type GetReputationScorePotentialQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetReputationScorePotentialQuery = {
  __typename?: 'Query'
  reputationScorePotential: { __typename?: 'ReputationScoreV2'; value: number }
}

export type GetScoreRelevanceGraphQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetScoreRelevanceGraphQuery = {
  __typename?: 'Query'
  scoresRelevanceGraph?: Array<{
    __typename?: 'RelevanceScore'
    company: string
    value: number
    date?: string | null
  }> | null
}

export type InsightsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type InsightsQuery = {
  __typename?: 'Query'
  insights?: {
    __typename?: 'Insight'
    companyId: number
    summary: string
    created: string
  } | null
}

export type PullInsightsJobStatusQueryVariables = Exact<{
  jobs: Array<Scalars['String']['input']> | Scalars['String']['input']
}>

export type PullInsightsJobStatusQuery = {
  __typename?: 'Query'
  pullInsightsJobStatus: Array<{
    __typename?: 'PullInsightsJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type PullInsightsMutationVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type PullInsightsMutation = {
  __typename?: 'Mutation'
  pullInsights: Array<{
    __typename?: 'PullInsightsJob'
    jobId: string
    status?: string | null
    message?: string | null
  }>
}

export type GetKeyOpportunitiesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetKeyOpportunitiesQuery = {
  __typename?: 'Query'
  keyOpportunities?: Array<{
    __typename?: 'KeyOpportunity'
    variable_name: string
    result_score: number
  }> | null
}

export type CompetitorsReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type CompetitorsReputationScoreQuery = {
  __typename?: 'Query'
  competitorsReputationScore: Array<{
    __typename?: 'Competitor'
    name: string
    value: number
  }>
}

export type GetScoresReputationGraphQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetScoresReputationGraphQuery = {
  __typename?: 'Query'
  scoresReputationGraph?: Array<{
    __typename?: 'Brands'
    company?: string | null
    data?: Array<{
      __typename?: 'BrandData'
      value?: number | null
      date?: string | null
    } | null> | null
  }> | null
}

export type GetScoresSentimentGraphQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetScoresSentimentGraphQuery = {
  __typename?: 'Query'
  scoresSentimentGraph?: Array<{
    __typename?: 'Brands'
    company?: string | null
    data?: Array<{
      __typename?: 'BrandData'
      value?: number | null
      date?: string | null
    } | null> | null
  }> | null
}

export type CompetitorsSentimentScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
}>

export type CompetitorsSentimentScoreQuery = {
  __typename?: 'Query'
  competitorsSentimentScore: Array<{
    __typename?: 'SentimentScoreV2'
    name: string
    value: number
  }>
}

export type GetArticlesOnclusiveQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate: Scalars['String']['input']
  endDate: Scalars['String']['input']
  pillarId?: InputMaybe<Scalars['Int']['input']>
  variableId?: InputMaybe<Scalars['Int']['input']>
}>

export type GetArticlesOnclusiveQuery = {
  __typename?: 'Query'
  articles: Array<{
    __typename?: 'Article'
    domain?: string | null
    title?: string | null
    url?: string | null
    author?: string | null
    summary?: string | null
    sentiment?: string | null
    sentiment_pos?: number | null
    sentiment_neg?: number | null
    published_on?: string | null
    media?: string | null
    amplification?: number | null
    reach?: number | null
    pagerank?: number | null
    country?: string | null
    lang?: string | null
    pillar_id?: number | null
    pillar_name?: string | null
    signature_variable_id?: number | null
    signature_variable_name?: string | null
  }>
}

export type GetArticlesCountQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesCountQuery = {
  __typename?: 'Query'
  articlesCount: Array<{
    __typename?: 'ArticlesCount'
    total: number
    date: string
  }>
}

export type GetArticlesSentimentQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetArticlesSentimentQuery = {
  __typename?: 'Query'
  articlesSentiment: {
    __typename?: 'ArticlesSentiment'
    positive?: number | null
    negative?: number | null
    neutral?: number | null
  }
}

export type GetShareVoiceQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetShareVoiceQuery = {
  __typename?: 'Query'
  shareVoice: Array<{
    __typename?: 'ShareVoice'
    company: string
    share?: number | null
  }>
}

export type PillarAverageScoreQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarAverageScoreQuery = {
  __typename?: 'Query'
  pillarAvgScore: Array<{
    __typename?: 'PillarScore'
    company_name: string
    avg?: number | null
  }>
}

export type PillarBehaviorScoreQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarBehaviorScoreQuery = {
  __typename?: 'Query'
  pillarBehaviorScore: { __typename?: 'BehaviorScore'; value: number }
}

export type PillarBehaviorTopCompetitorScoreQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarBehaviorTopCompetitorScoreQuery = {
  __typename?: 'Query'
  pillarBehaviorTopCompetitorScore: {
    __typename?: 'BehaviorScore'
    company_name?: string | null
    value: number
  }
}

export type PillarDailyScoreQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarDailyScoreQuery = {
  __typename?: 'Query'
  pillarDailyScore: Array<{
    __typename?: 'PillarScore'
    company_name: string
    date?: string | null
    avg?: number | null
  }>
}

export type PillarVariableInfluencingQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarVariableInfluencingQuery = {
  __typename?: 'Query'
  pillarVariableInfluencing: Array<{
    __typename?: 'PillarVariableInfluencer'
    company_id?: number | null
    company_name: string
    variable_id: number
    variable_name: string
    current_avg: number
    prev_avg?: number | null
    change?: number | null
  }>
}

export type PillarVariableInfluencingArticleQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarVariableInfluencingArticleQuery = {
  __typename?: 'Query'
  pillarVariableInfluencingArticle: Array<{
    __typename?: 'PillarVariableInfluencerArticle'
    title: string
    url: string
    domain: string
    published_on: string
    variable_id: number
  }>
}

export type PillarKeyResultsQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarKeyResultsQuery = {
  __typename?: 'Query'
  pillarKeyResults: Array<{
    __typename?: 'PillarKeyResult'
    variable_name: string
    sentiment_score?: number | null
    date?: string | null
    behavior_score?: number | null
    key_score?: number | null
    key: string
  }>
}

export type TopCompetitorMentionsQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type TopCompetitorMentionsQuery = {
  __typename?: 'Query'
  topCompetitorMentions: Array<{
    __typename?: 'PillarVariableInfluencerArticle'
    title: string
    url: string
    domain: string
    published_on: string
    variable_id: number
  }>
}

export type PillarMentionsCountQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarMentionsCountQuery = {
  __typename?: 'Query'
  pillarMentionsCount: Array<{
    __typename?: 'PillarMentions'
    company_name: string
    date: string
    mentions: number
  }>
}

export type PillarPotentialScoreQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarPotentialScoreQuery = {
  __typename?: 'Query'
  pillarPotentialScore: Array<{
    __typename?: 'PillarPotentialScore'
    company_name: string
    potential?: number | null
    avg?: number | null
  }>
}

export type PillarSharedVoiceQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarSharedVoiceQuery = {
  __typename?: 'Query'
  pillarMentionShareVoice: Array<{
    __typename?: 'PillarMentionShareVoice'
    company_name: string
    value: number
  }>
}

export type PillarNewsSentimentQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarNewsSentimentQuery = {
  __typename?: 'Query'
  pillarNewsSentiment: Array<{
    __typename?: 'PillarNewsSentiment'
    sentiment_type: string
    value: number
  }>
}

export type PillarVelocityQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type PillarVelocityQuery = {
  __typename?: 'Query'
  pillarVelocity: Array<{
    __typename?: 'PillarVelocity'
    company_name: string
    last_avg?: number | null
    prev_avg?: number | null
    result?: number | null
  }>
}

export type SentimentMentionsQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type SentimentMentionsQuery = {
  __typename?: 'Query'
  sentimentMentions: Array<{
    __typename?: 'SentimentMentions'
    company_name: string
    company_id: number
    total_mentions: number
    mentions: Array<{
      __typename?: 'DailyMentions'
      date: string
      mentions: number
    }>
  }>
}

export type SentimentPillarChangesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type SentimentPillarChangesQuery = {
  __typename?: 'Query'
  sentimentPillarChanges: Array<{
    __typename?: 'SentimentPillarChange'
    variable_name: string
    variable_id: number
    diff: number
  }>
}

export type SentimentVelocityQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type SentimentVelocityQuery = {
  __typename?: 'Query'
  sentimentVelocity: Array<{
    __typename?: 'SentimentVelocity'
    company_name: string
    last_avg: number
    prev_avg?: number | null
    result?: number | null
  }>
}

export type SentimentVoiceSharesQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type SentimentVoiceSharesQuery = {
  __typename?: 'Query'
  sentimentVoiceShares: Array<{
    __typename?: 'SentimentVoiceShare'
    company_name: string
    voice_share: number
  }>
}

export type VariablesInfluencingSentimentQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type VariablesInfluencingSentimentQuery = {
  __typename?: 'Query'
  variablesInfluencingSentiment: Array<{
    __typename?: 'SentimentVariableChange'
    variable_name: string
    pillar_name: string
    pillar_id?: number | null
    value: number
    diff: number
  }>
}

export type TopArticlesInfluencingVariableQueryVariables = Exact<{
  pillarId: Scalars['Int']['input']
  clientId: Scalars['ID']['input']
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type TopArticlesInfluencingVariableQuery = {
  __typename?: 'Query'
  pillarVariableInfluencingArticle: Array<{
    __typename?: 'PillarVariableInfluencerArticle'
    title: string
    url: string
    domain: string
    published_on: string
    variable_id: number
  }>
}

export type GetSentimentScoreQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentScoreQuery = {
  __typename?: 'Query'
  sentimentScore?: Array<{
    __typename?: 'SentimentScore'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    dataType: RefinitivDataType
  } | null> | null
}

export type GetPerceptionRealityGapQueryVariables = Exact<{
  clientId?: InputMaybe<Scalars['ID']['input']>
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetPerceptionRealityGapQuery = {
  __typename?: 'Query'
  perceptionRealityGap?: {
    __typename?: 'PerceptionRealityGap'
    perception?: Array<{
      __typename?: 'Perception'
      score?: number | null
      averageScore?: number | null
      variableId: string
      variableName: string
      dataType: RefinitivDataType
    } | null> | null
    signature?: Array<{
      __typename?: 'Signature'
      name?: string | null
      pillar?: string | null
      value?: number | null
      optimum?: number | null
    } | null> | null
  } | null
}

export type GetReputationScoreQueryVariables = Exact<{
  clientId: Scalars['ID']['input']
  industryId?: InputMaybe<Scalars['ID']['input']>
}>

export type GetReputationScoreQuery = {
  __typename?: 'Query'
  reputationScore?: {
    __typename?: 'ReputationScore'
    score: number
    difference?: number | null
    compareVsPeriod?: string | null
  } | null
}

export type GetSentimentExecutiveSummaryQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
  source?: InputMaybe<RefinitivDataSource>
}>

export type GetSentimentExecutiveSummaryQuery = {
  __typename?: 'Query'
  sentimentExecutiveSummary?: {
    __typename?: 'SentimentExecutiveSummary'
    mentions?: Array<{
      __typename?: 'Mentions'
      companyId: string
      companyName: string
      count?: number | null
    } | null> | null
    score?: Array<{
      __typename?: 'ExecutiveSummarySentimentScore'
      companyId: string
      companyName: string
      score?: number | null
    } | null> | null
  } | null
}

export type GetSentimentByPillarQueryVariables = Exact<{
  industryId: Scalars['ID']['input']
  clientId?: InputMaybe<Scalars['ID']['input']>
  startDate?: InputMaybe<Scalars['String']['input']>
  endDate?: InputMaybe<Scalars['String']['input']>
}>

export type GetSentimentByPillarQuery = {
  __typename?: 'Query'
  sentimentByPillar?: Array<{
    __typename?: 'SentimentData'
    score?: number | null
    averageScore?: number | null
    leaderScore?: number | null
    variableId: string
    variableName: string
    dataType: RefinitivDataType
  } | null> | null
}

export type GetSignatureExecutiveSummaryQueryVariables = Exact<{
  industryId?: InputMaybe<Scalars['ID']['input']>
  clientId: Scalars['ID']['input']
}>

export type GetSignatureExecutiveSummaryQuery = {
  __typename?: 'Query'
  signatureExecutiveSummary?: {
    __typename?: 'SignatureExecutiveSummary'
    drivers?: Array<{
      __typename?: 'ReputationDriver'
      variableId: string
      variableName: string
      weight?: number | null
    } | null> | null
    competitorsGains?: Array<{
      __typename?: 'CompanyGain'
      companyId: string
      companyName: string
      score?: number | null
      differenceVsPrevious?: number | null
    } | null> | null
  } | null
}

export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'password' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'password' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'respondentUuid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'logout' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>
export const CreateUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreateUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rank' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateUserMutation, CreateUserMutationVariables>
export const DeleteUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>
export const EditUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EditUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UserInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'editUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'user' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'roleId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'role' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'rank' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'accountStatus' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EditUserMutation, EditUserMutationVariables>
export const UploadUsersRosterFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadUsersRosterFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadUsersRosterFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadUsersRosterFileMutation,
  UploadUsersRosterFileMutationVariables
>
export const SendResetPasswordEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPasswordEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'redirectTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'redirectTo' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'redirectTo' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendResetPasswordEmailMutation,
  SendResetPasswordEmailMutationVariables
>
export const VerifyResetPasswordTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyResetPasswordToken' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyResetPasswordToken' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VerifyResetPasswordTokenMutation,
  VerifyResetPasswordTokenMutationVariables
>
export const ChangeUserPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeUserPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeUserPassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'token' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeUserPasswordMutation,
  ChangeUserPasswordMutationVariables
>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'newPassword' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'newPassword' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>
export const AddEmailToWhitelistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddEmailToWhitelist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'emailOrDomain' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addEmailToWhitelist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'emailOrDomain' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'emailOrDomain' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddEmailToWhitelistMutation,
  AddEmailToWhitelistMutationVariables
>
export const ChangeVariableScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'changeVariableScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variableName' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'value' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Float' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeVariableScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variableName' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variableName' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'value' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'value' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ChangeVariableScoreMutation,
  ChangeVariableScoreMutationVariables
>
export const ResetVariableScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'resetVariableScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetVariableScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetVariableScoreMutation,
  ResetVariableScoreMutationVariables
>
export const PullRefinitivDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullRefinitivData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullRefinitivData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullRefinitivDataMutation,
  PullRefinitivDataMutationVariables
>
export const CreateChatDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createChat' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createChat' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CreateChatMutation, CreateChatMutationVariables>
export const CreateMessageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createMessage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'chatId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createMessage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'chatId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'chatId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'content' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateMessageMutation,
  CreateMessageMutationVariables
>
export const CurrentUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'currentUser' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentUser' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'respondentUuid' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'clientName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'emailHash' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'roles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'settings' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mustResetPassword' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CurrentUserQuery, CurrentUserQueryVariables>
export const ClientsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'clients' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'clients' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClientsQuery, ClientsQueryVariables>
export const UserDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'teamsIds' },
          },
          type: {
            kind: 'ListType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userDetails' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'teamsIds' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'teamsIds' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                { kind: 'Field', name: { kind: 'Name', value: 'roleId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'role' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rank' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accountStatus' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'jobTitle' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UserDetailsQuery, UserDetailsQueryVariables>
export const EmailWhitelistDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailWhitelist' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailWhitelist' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'emailOrDomain' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailWhitelistQuery, EmailWhitelistQueryVariables>
export const CheckIfEmailWhitelistedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckIfEmailWhitelisted' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'client' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'email' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkIfEmailWhitelisted' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'client' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'client' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'email' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'email' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckIfEmailWhitelistedQuery,
  CheckIfEmailWhitelistedQueryVariables
>
export const YearsInPurposeSignatureChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'yearsInPurposeSignatureChart' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsInPurposeSignatureChart' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  YearsInPurposeSignatureChartQuery,
  YearsInPurposeSignatureChartQueryVariables
>
export const CompaniesInPurposeSignatureChartDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companiesInPurposeSignatureChart' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companiesInPurposeSignatureChart' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'clientCode' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompaniesInPurposeSignatureChartQuery,
  CompaniesInPurposeSignatureChartQueryVariables
>
export const TeamPurposeSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamPurposeSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'year' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamPurposeSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'year' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'year' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataForSignatureMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TeamPurposeSignatureQuery,
  TeamPurposeSignatureQueryVariables
>
export const SessionPurposeSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sessionPurposeSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'groupId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companyId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'restoreUserSession' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sessionPurposeSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'groupId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'groupId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companyId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companyId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'restoreUserSession' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'restoreUserSession' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'current' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimum' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'dataForSignatureMatch' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'y' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'original' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'company' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'scoreVsCompany' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalVsCompany' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SessionPurposeSignatureQuery,
  SessionPurposeSignatureQueryVariables
>
export const PurposeDriversSignatureDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposeDriversSignature' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposeDriversSignature' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'differenceWithPastQuarter' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PurposeDriversSignatureQuery,
  PurposeDriversSignatureQueryVariables
>
export const ReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'reputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ReputationScoreQuery,
  ReputationScoreQueryVariables
>
export const CompanyVsTopMostPurposeDrivenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'companyVsTopMostPurposeDriven' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'companyVsTopMostPurposeDriven' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'differenceWithPastQuarter' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompanyVsTopMostPurposeDrivenQuery,
  CompanyVsTopMostPurposeDrivenQueryVariables
>
export const PurposePillarsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposePillars' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposePillars' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'drilldown' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previousValue' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'compareVs' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optimumDrilldown' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurposePillarsQuery, PurposePillarsQueryVariables>
export const HighRiskAreasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'highRiskAreas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'highRiskAreas' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<HighRiskAreasQuery, HighRiskAreasQueryVariables>
export const SignatureMatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'signatureMatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatureMatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'previousValue' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'compareVs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'optimum' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previousValue' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'compareVs' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'optimumDrilldown' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SignatureMatchQuery, SignatureMatchQueryVariables>
export const ExploreDataByYearDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exploreDataByYear' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exploreDataByYear' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExploreDataByYearQuery,
  ExploreDataByYearQueryVariables
>
export const ExploreDataByIndustryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'exploreDataByIndustry' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exploreDataByIndustry' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'categories' } },
                { kind: 'Field', name: { kind: 'Name', value: 'average' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'data' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ExploreDataByIndustryQuery,
  ExploreDataByIndustryQueryVariables
>
export const GetTrendingTopicsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrendingTopics' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trendingTopics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variableId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pillarName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrendingTopicsQuery,
  GetTrendingTopicsQueryVariables
>
export const IndustriesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'industries' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'industries' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'forClient' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<IndustriesQuery, IndustriesQueryVariables>
export const PurposeMatchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'purposeMatch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'purposeMatch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PurposeMatchQuery, PurposeMatchQueryVariables>
export const VariableGroupsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'variableGroups' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variableGroups' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VariableGroupsQuery, VariableGroupsQueryVariables>
export const GetHomeTabSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabSentimentScoreQuery,
  GetHomeTabSentimentScoreQueryVariables
>
export const GetHomeTabReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabReputationScoreQuery,
  GetHomeTabReputationScoreQueryVariables
>
export const GetHomeTabPerceptionRealityGapScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeTabPerceptionRealityGapScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perceptionRealityGapScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetHomeTabPerceptionRealityGapScoreQuery,
  GetHomeTabPerceptionRealityGapScoreQueryVariables
>
export const CalculateBehaviorDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'calculateBehaviorData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateBehaviorData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CalculateBehaviorDataMutation,
  CalculateBehaviorDataMutationVariables
>
export const PullCalculateBehaviorDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCalculateBehaviorDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCalculateBehaviorDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCalculateBehaviorDataJobStatusQuery,
  PullCalculateBehaviorDataJobStatusQueryVariables
>
export const CalculateSentimentDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'calculateSentimentData' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'calculateSentimentData' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CalculateSentimentDataMutation,
  CalculateSentimentDataMutationVariables
>
export const PullCalculateSentimentDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCalculateSentimentDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: {
              kind: 'Name',
              value: 'pullCalculateSentimentDataJobStatus',
            },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCalculateSentimentDataJobStatusQuery,
  PullCalculateSentimentDataJobStatusQueryVariables
>
export const DownloadPerceptionRealityGapDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'downloadPerceptionRealityGapData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'downloadPerceptionRealityGapData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DownloadPerceptionRealityGapDataQuery,
  DownloadPerceptionRealityGapDataQueryVariables
>
export const PullArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullArticlesMutation,
  PullArticlesMutationVariables
>
export const PullArticlesJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullArticlesJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullArticlesJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullArticlesJobStatusQuery,
  PullArticlesJobStatusQueryVariables
>
export const PullCategorizedArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullCategorizedArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'limit' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'companies' },
          },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCategorizedArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'limit' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'companies' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'companies' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCategorizedArticlesMutation,
  PullCategorizedArticlesMutationVariables
>
export const CancelCategorizationJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CancelCategorizationJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'cancelCategorizationJobs' },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CancelCategorizationJobsMutation,
  CancelCategorizationJobsMutationVariables
>
export const PullCategorizedArticlesJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCategorizedArticlesJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCategorizedArticlesJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCategorizedArticlesJobStatusQuery,
  PullCategorizedArticlesJobStatusQueryVariables
>
export const PullCompanyExplorerDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullCompanyExplorerData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCompanyExplorerData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'date' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'date' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCompanyExplorerDataMutation,
  PullCompanyExplorerDataMutationVariables
>
export const PullCompanyExplorerDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullCompanyExplorerDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullCompanyExplorerDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullCompanyExplorerDataJobStatusQuery,
  PullCompanyExplorerDataJobStatusQueryVariables
>
export const PullRefinitivDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullRefinitivDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'jobId' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullRefinitivDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullRefinitivDataJobStatusQuery,
  PullRefinitivDataJobStatusQueryVariables
>
export const PullSentimentHistoryDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullSentimentHistoryData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullSentimentHistoryData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullSentimentHistoryDataMutation,
  PullSentimentHistoryDataMutationVariables
>
export const PullSentimentHistoryDataJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullSentimentHistoryDataJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullSentimentHistoryDataJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullSentimentHistoryDataJobStatusQuery,
  PullSentimentHistoryDataJobStatusQueryVariables
>
export const ScoreArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'scoreArticles' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'scoreArticles' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ScoreArticlesMutation,
  ScoreArticlesMutationVariables
>
export const BehaviorDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BehaviorData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'behaviorData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                { kind: 'Field', name: { kind: 'Name', value: 'quarter' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavior_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'community' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'financials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'governance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'community_development' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance_reporting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diversity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_compensation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_engagement' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ethics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'financial_performance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'health_safety' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'human_rights_and_equality' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadership' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loyalty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'net_zero' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_services' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resource_management' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vision' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BehaviorDataQuery, BehaviorDataQueryVariables>
export const AddCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'addCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreateCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'company' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'company' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'assetCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSensitiveTerms' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domainsTerms' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competitors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'competitorId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCompanyMutation, AddCompanyMutationVariables>
export const UpdateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'company' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'company' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'company' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'countryCode' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'industry' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'assetCode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ticker' } },
                { kind: 'Field', name: { kind: 'Name', value: 'terms' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseSensitiveTerms' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'website' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domains' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'domainsTerms' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competitors' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'competitorId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>
export const DeleteCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'deleted' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCompanyMutation,
  DeleteCompanyMutationVariables
>
export const CustomerReportsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CustomerReports' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'customerReports' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customer' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CustomerReportsQuery,
  CustomerReportsQueryVariables
>
export const UploadCustomerReportFileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UploadCustomerReportFile' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'uploadCustomerReportFile' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'file' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'file' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UploadCustomerReportFileMutation,
  UploadCustomerReportFileMutationVariables
>
export const DeleteCustomerReportDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteCustomerReport' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteCustomerReport' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                { kind: 'Field', name: { kind: 'Name', value: 'success' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteCustomerReportMutation,
  DeleteCustomerReportMutationVariables
>
export const CategorizedArticlesJobsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CategorizedArticlesJobs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'categorizedArticlesJobs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'tag' } },
                { kind: 'Field', name: { kind: 'Name', value: 'jobsLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'rate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CategorizedArticlesJobsQuery,
  CategorizedArticlesJobsQueryVariables
>
export const SentimentDataDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SentimentData' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'start' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'end' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentData' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'start' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'start' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'end' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'end' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'community' } },
                { kind: 'Field', name: { kind: 'Name', value: 'customers' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dei' } },
                { kind: 'Field', name: { kind: 'Name', value: 'employees' } },
                { kind: 'Field', name: { kind: 'Name', value: 'environment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'financials' } },
                { kind: 'Field', name: { kind: 'Name', value: 'governance' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'community_development' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compliance_reporting' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'diversity' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_compensation' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'employee_engagement' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'ethics' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'financial_performance' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'health_safety' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'human_rights_and_equality' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leadership' } },
                { kind: 'Field', name: { kind: 'Name', value: 'loyalty' } },
                { kind: 'Field', name: { kind: 'Name', value: 'net_zero' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'products_services' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resource_management' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'supplier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vision' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SentimentDataQuery, SentimentDataQueryVariables>
export const AddSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'features' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PaidFeature' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'features' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddSubscriptionMutation,
  AddSubscriptionMutationVariables
>
export const SubscriptionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Subscriptions' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscriptions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'startDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'endDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'features' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'client' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SubscriptionsQuery, SubscriptionsQueryVariables>
export const CheckActiveSubscriptionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CheckActiveSubscription' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'features' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'PaidFeature' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'checkActiveSubscription' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'features' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'features' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckActiveSubscriptionQuery,
  CheckActiveSubscriptionQueryVariables
>
export const GetAlertsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlerts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'alerts' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'definition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg_score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trend' } },
                { kind: 'Field', name: { kind: 'Name', value: 'stddev' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'daily_avg_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAlertsQuery, GetAlertsQueryVariables>
export const BehaviorInfluencingArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'behaviorInfluencingArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'behaviorInfluencingArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'pillar_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'articles' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published_on' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BehaviorInfluencingArticlesQuery,
  BehaviorInfluencingArticlesQueryVariables
>
export const BehaviorScoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'behaviorScores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'behaviorScores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'change' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<BehaviorScoresQuery, BehaviorScoresQueryVariables>
export const BehaviorPillarsScoresDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'behaviorPillarsScores' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'behaviorPillarsScores' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pillars' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar_id' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signatures' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar_name' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar_id' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variables' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  BehaviorPillarsScoresQuery,
  BehaviorPillarsScoresQueryVariables
>
export const GetSentimentBehaviorGapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentBehaviorGap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoreSentimentGapBehavior' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'gap' } },
                { kind: 'Field', name: { kind: 'Name', value: 'behavior' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentBehaviorGapQuery,
  GetSentimentBehaviorGapQueryVariables
>
export const AverageStockPriceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AverageStockPrice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'averageStockPrice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'avg_stock' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AverageStockPriceQuery,
  AverageStockPriceQueryVariables
>
export const CoverageMixDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CoverageMix' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'coverageMix' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'percent' } },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CoverageMixQuery, CoverageMixQueryVariables>
export const GetDailyReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDailyReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresReputationGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetDailyReputationScoreQuery,
  GetDailyReputationScoreQueryVariables
>
export const DailyStocksMeanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'DailyStocksMean' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'dailyStocks' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DailyStocksMeanQuery,
  DailyStocksMeanQueryVariables
>
export const FinancialInfluencerScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancialInfluencerScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financialInfluencerScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'count' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialInfluencerScoreQuery,
  FinancialInfluencerScoreQueryVariables
>
export const FinancialMediaMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancialMediaMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financialMediaMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'media_mentions' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialMediaMentionsQuery,
  FinancialMediaMentionsQueryVariables
>
export const FinancialSentimentDailyScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancialSentimentDailyScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financialSentimentDailyScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialSentimentDailyScoreQuery,
  FinancialSentimentDailyScoreQueryVariables
>
export const FinancialSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancialSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financialSentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialSentimentScoreQuery,
  FinancialSentimentScoreQueryVariables
>
export const FinancialInfluencingArticlesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FinancialInfluencingArticles' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'financialInfluencingArticles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FinancialInfluencingArticlesQuery,
  FinancialInfluencingArticlesQueryVariables
>
export const CompetitorsMarketShareDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompetitorsMarketShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitorsMarketShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompetitorsMarketShareQuery,
  CompetitorsMarketShareQueryVariables
>
export const StockPriceChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'StockPriceChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'stockChange' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  StockPriceChangeQuery,
  StockPriceChangeQueryVariables
>
export const TopicsInfluencingScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopicsInfluencingScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topics' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopicsInfluencingScoreQuery,
  TopicsInfluencingScoreQueryVariables
>
export const GetReputationScoreV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreV2Query,
  GetReputationScoreV2QueryVariables
>
export const GetReputationScoreChangeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreChange' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreChangeV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreChangeQuery,
  GetReputationScoreChangeQueryVariables
>
export const GetReputationScoreDiverDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScoreDiver' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScoreDriverV2' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current_value' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreDiverQuery,
  GetReputationScoreDiverQueryVariables
>
export const GetMarketShareDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMarketShare' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'marketShare' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMarketShareQuery, GetMarketShareQueryVariables>
export const GetMediaMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMediaMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'mediaMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetMediaMentionsQuery,
  GetMediaMentionsQueryVariables
>
export const GetReputationInfluencersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationInfluencers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationInfluencers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'values' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'domain' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'published_on' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationInfluencersQuery,
  GetReputationInfluencersQueryVariables
>
export const GetTrafficValueDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrafficValue' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'trafficValue' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTrafficValueQuery,
  GetTrafficValueQueryVariables
>
export const GetPillarReputationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPillarReputation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarReputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPillarReputationQuery,
  GetPillarReputationQueryVariables
>
export const GetPillarReputationChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPillarReputationChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarReputationChangesScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPillarReputationChangesQuery,
  GetPillarReputationChangesQueryVariables
>
export const CompetitorsRelevanceScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompetitorsRelevanceScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitorsRelevanceScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompetitorsRelevanceScoreQuery,
  CompetitorsRelevanceScoreQueryVariables
>
export const GetReputationScorePotentialDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScorePotential' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScorePotential' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScorePotentialQuery,
  GetReputationScorePotentialQueryVariables
>
export const GetScoreRelevanceGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScoreRelevanceGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresRelevanceGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoreRelevanceGraphQuery,
  GetScoreRelevanceGraphQueryVariables
>
export const InsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Insights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'insights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'companyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'created' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InsightsQuery, InsightsQueryVariables>
export const PullInsightsJobStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'pullInsightsJobStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'jobs' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'String' },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullInsightsJobStatus' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'jobs' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'jobs' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullInsightsJobStatusQuery,
  PullInsightsJobStatusQueryVariables
>
export const PullInsightsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'pullInsights' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pullInsights' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'jobId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'message' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PullInsightsMutation,
  PullInsightsMutationVariables
>
export const GetKeyOpportunitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetKeyOpportunities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'keyOpportunities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result_score' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetKeyOpportunitiesQuery,
  GetKeyOpportunitiesQueryVariables
>
export const CompetitorsReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompetitorsReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitorsReputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompetitorsReputationScoreQuery,
  CompetitorsReputationScoreQueryVariables
>
export const GetScoresReputationGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScoresReputationGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresReputationGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoresReputationGraphQuery,
  GetScoresReputationGraphQueryVariables
>
export const GetScoresSentimentGraphDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetScoresSentimentGraph' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'scoresSentimentGraph' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'data' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetScoresSentimentGraphQuery,
  GetScoresSentimentGraphQueryVariables
>
export const CompetitorsSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'CompetitorsSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'competitorsSentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CompetitorsSentimentScoreQuery,
  CompetitorsSentimentScoreQueryVariables
>
export const GetArticlesOnclusiveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesOnclusive' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'variableId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articles' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'variableId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'variableId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'author' } },
                { kind: 'Field', name: { kind: 'Name', value: 'summary' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sentiment' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_pos' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_neg' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'media' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'amplification' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'reach' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pagerank' } },
                { kind: 'Field', name: { kind: 'Name', value: 'country' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lang' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pillar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pillar_name' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signature_variable_id' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signature_variable_name' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesOnclusiveQuery,
  GetArticlesOnclusiveQueryVariables
>
export const GetArticlesCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesCountQuery,
  GetArticlesCountQueryVariables
>
export const GetArticlesSentimentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetArticlesSentiment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'articlesSentiment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'positive' } },
                { kind: 'Field', name: { kind: 'Name', value: 'negative' } },
                { kind: 'Field', name: { kind: 'Name', value: 'neutral' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetArticlesSentimentQuery,
  GetArticlesSentimentQueryVariables
>
export const GetShareVoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetShareVoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareVoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company' } },
                { kind: 'Field', name: { kind: 'Name', value: 'share' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetShareVoiceQuery, GetShareVoiceQueryVariables>
export const PillarAverageScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarAverageScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarAvgScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarAverageScoreQuery,
  PillarAverageScoreQueryVariables
>
export const PillarBehaviorScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarBehaviorScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarBehaviorScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarBehaviorScoreQuery,
  PillarBehaviorScoreQueryVariables
>
export const PillarBehaviorTopCompetitorScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarBehaviorTopCompetitorScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarBehaviorTopCompetitorScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarBehaviorTopCompetitorScoreQuery,
  PillarBehaviorTopCompetitorScoreQueryVariables
>
export const PillarDailyScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarDailyScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarDailyScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarDailyScoreQuery,
  PillarDailyScoreQueryVariables
>
export const PillarVariableInfluencingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarVariableInfluencing' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarVariableInfluencing' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'current_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prev_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'change' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarVariableInfluencingQuery,
  PillarVariableInfluencingQueryVariables
>
export const PillarVariableInfluencingArticleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarVariableInfluencingArticle' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarVariableInfluencingArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarVariableInfluencingArticleQuery,
  PillarVariableInfluencingArticleQueryVariables
>
export const PillarKeyResultsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarKeyResults' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarKeyResults' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'behavior_score' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'key_score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'key' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarKeyResultsQuery,
  PillarKeyResultsQueryVariables
>
export const TopCompetitorMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopCompetitorMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'topCompetitorMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopCompetitorMentionsQuery,
  TopCompetitorMentionsQueryVariables
>
export const PillarMentionsCountDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarMentionsCount' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarMentionsCount' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mentions' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarMentionsCountQuery,
  PillarMentionsCountQueryVariables
>
export const PillarPotentialScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarPotentialScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarPotentialScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'potential' } },
                { kind: 'Field', name: { kind: 'Name', value: 'avg' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarPotentialScoreQuery,
  PillarPotentialScoreQueryVariables
>
export const PillarSharedVoiceDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarSharedVoice' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarMentionShareVoice' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarSharedVoiceQuery,
  PillarSharedVoiceQueryVariables
>
export const PillarNewsSentimentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarNewsSentiment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarNewsSentiment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sentiment_type' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  PillarNewsSentimentQuery,
  PillarNewsSentimentQueryVariables
>
export const PillarVelocityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PillarVelocity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarVelocity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'last_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prev_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PillarVelocityQuery, PillarVelocityQueryVariables>
export const SentimentMentionsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sentimentMentions' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentMentions' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'company_id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total_mentions' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mentions' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentMentionsQuery,
  SentimentMentionsQueryVariables
>
export const SentimentPillarChangesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sentimentPillarChanges' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentPillarChanges' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diff' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentPillarChangesQuery,
  SentimentPillarChangesQueryVariables
>
export const SentimentVelocityDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sentimentVelocity' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentVelocity' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'last_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'prev_avg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'result' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentVelocityQuery,
  SentimentVelocityQueryVariables
>
export const SentimentVoiceSharesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'sentimentVoiceShares' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentVoiceShares' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'voice_share' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SentimentVoiceSharesQuery,
  SentimentVoiceSharesQueryVariables
>
export const VariablesInfluencingSentimentDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'variablesInfluencingSentiment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'variablesInfluencingSentiment' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variable_name' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'pillar_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'pillar_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                { kind: 'Field', name: { kind: 'Name', value: 'diff' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  VariablesInfluencingSentimentQuery,
  VariablesInfluencingSentimentQueryVariables
>
export const TopArticlesInfluencingVariableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'TopArticlesInfluencingVariable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'pillarId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'pillarVariableInfluencingArticle' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pillarId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'pillarId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'domain' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'published_on' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'variable_id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  TopArticlesInfluencingVariableQuery,
  TopArticlesInfluencingVariableQueryVariables
>
export const GetSentimentScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentScoreQuery,
  GetSentimentScoreQueryVariables
>
export const GetPerceptionRealityGapDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPerceptionRealityGap' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'perceptionRealityGap' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'perception' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'averageScore' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'dataType' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'signature' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'pillar' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'optimum' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPerceptionRealityGapQuery,
  GetPerceptionRealityGapQueryVariables
>
export const GetReputationScoreDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetReputationScore' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reputationScore' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                { kind: 'Field', name: { kind: 'Name', value: 'difference' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'compareVsPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetReputationScoreQuery,
  GetReputationScoreQueryVariables
>
export const GetSentimentExecutiveSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentExecutiveSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'source' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RefinitivDataSource' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentExecutiveSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'source' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'source' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'mentions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'score' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentExecutiveSummaryQuery,
  GetSentimentExecutiveSummaryQueryVariables
>
export const GetSentimentByPillarDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSentimentByPillar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'startDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'endDate' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sentimentByPillar' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'startDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'startDate' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'endDate' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'endDate' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'averageScore' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'leaderScore' } },
                { kind: 'Field', name: { kind: 'Name', value: 'variableId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variableName' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'dataType' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSentimentByPillarQuery,
  GetSentimentByPillarQueryVariables
>
export const GetSignatureExecutiveSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSignatureExecutiveSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'industryId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'clientId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'signatureExecutiveSummary' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'industryId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'industryId' },
                },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'clientId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'clientId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drivers' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variableName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'weight' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'competitorsGains' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'companyName' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'score' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'differenceVsPrevious' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetSignatureExecutiveSummaryQuery,
  GetSignatureExecutiveSummaryQueryVariables
>
