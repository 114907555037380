import { ThemeProvider } from '@mui/material/styles'
import NavigationProvider from 'Context/navigation-context'
import { useEffect } from 'react'
import { ChatBotAIProvider } from './Providers/ChatBotAIProvider'
import { ClientProvider } from './Providers/ClientProvider'
import { DataSourceProvider } from './Providers/DataSourceProvider'
import { FeatureFlagProvider } from './Providers/FeatureFlagsProvider'
import { IndustryProvider } from './Providers/IndustryProvider'
import { TimeSeriesProvider } from './Providers/TimeSeriesProvider'
import { UserInfoProvider } from './Providers/UserInfoProvider'
import { ColorsProvider } from './Providers/ColorsProvider'
import { features } from './featureFlags'
import { AppRoutes } from './routes'
import { theme } from './theme'

const App = () => {
  useEffect(() => {
    const app = import.meta.env.VITE_APP_NAME
    const sha = import.meta.env.VITE_GIT_SHA
    const message = `
      application: ${app}
      git-sha:     ${sha ?? 'unknown'}
      `
    console.info(message)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <FeatureFlagProvider features={features}>
        <UserInfoProvider>
          <ClientProvider>
            <IndustryProvider>
              <TimeSeriesProvider>
                <DataSourceProvider>
                  <NavigationProvider>
                    <ChatBotAIProvider>
                      <ColorsProvider>
                        <AppRoutes />
                      </ColorsProvider>
                    </ChatBotAIProvider>
                  </NavigationProvider>
                </DataSourceProvider>
              </TimeSeriesProvider>
            </IndustryProvider>
          </ClientProvider>
        </UserInfoProvider>
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}

export default App
